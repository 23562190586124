
































































import { Component, Ref, Vue } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import QuestionNavigation from '@/components/project/make/question/QuestionNavigation.vue';
import { IQuestionValue } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';
import EventBus from '@/utils/EventBus';
import ResultChartContent from '@/components/project/result/content/ResultChartContent.vue';
import QuotaParticipation from '@/components/project/quota/participation/QuotaParticipation.vue';
import Button from "@/components/common/Button.vue";
import ScrollTopButton from "@/components/common/ScrollTopButton.vue";

@Component({
  components: {
    ScrollTopButton,
    QuotaParticipation,
    QuestionNavigation,
    ResultChartContent,
    ProjectTopMenu,
    Button
  },
})
export default class Result extends Vue {
  @Ref() resultChartContentRef: ResultChartContent;

  questionInfo: Array<IQuestionValue> = [QUESTION.tempQuestionData()];

  private singleView: boolean = false;
  private completeCount: number = 0;
  private svyNumber: number = +this.$route.params.id;
  private dataName: string = '';
  private loading: boolean = true;
  private isNoResult: boolean = false;

  private surveyParticipations = {
    ALL: { NAME: 'ALL', COUNT: 0 },
    BAD: { NAME: 'BAD', COUNT: 0 },
    COMPLETE: { NAME: 'COMPLETE', COUNT: 0 },
    DROP: { NAME: 'DROP', COUNT: 0 },
    OVER: { NAME: 'OVER', COUNT: 0 },
    OUT: { NAME: 'OUT', COUNT: 0 },
  };
  limitCount: number = 0;

  sampleRenderOver = false

  async created() {
    this.initEventBus();
    await this.quotaLoad();
    await this.load();

    if (this.isNoResult) EventBus.$emit('noResult-hideBtn');
  }

  async load(): Promise<void> {
    try {
      const params = this.getLoadSendData();
      const { data } = await this.axios({
        url: `/result/chart`,
        method: 'POST',
        data: params,
      });

      const { result } = data;
      this.isNoResult = result.length === 0;
      this.questionInfo = result;
      EventBus.$emit('loadingFalse');
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async quotaLoad() {
    try {
      const { data } = await this.axios.get(`/project/quota/info/${this.svyNumber}`);
      const { info, quota } = data;
      const { DATA } = info;

      const { complete } = quota;
      if (complete) this.completeCount = complete;

      const quotaArray = DATA.filter((item) => item.TYPE === QUESTION.QUESTION_TYPES.QUOTA);
      quotaArray.forEach((data) => {
        const { QUOTA } = data;
        let sum = 0;
        for (const row in QUOTA) {
          sum += Number(QUOTA[row]['quota']);
        }
        this.limitCount += sum;
      });

      if (quota) {
        const { all, drop, complete, bad, out, over } = quota;
        if (all) this.surveyParticipations.ALL = { NAME: 'ALL', COUNT: all };
        else this.surveyParticipations.ALL = { NAME: 'ALL', COUNT: 0 };
        if (bad) this.surveyParticipations.BAD = { NAME: 'ALL', COUNT: bad };
        else this.surveyParticipations.BAD = { NAME: 'BAD', COUNT: 0 };
        if (complete) this.surveyParticipations.COMPLETE = { NAME: 'ALL', COUNT: complete };
        else this.surveyParticipations.COMPLETE = { NAME: 'COMPLETE', COUNT: 0 };
        if (drop) this.surveyParticipations.DROP = { NAME: 'ALL', COUNT: drop };
        else this.surveyParticipations.DROP = { NAME: 'DROP', COUNT: 0 };
        if (out) this.surveyParticipations.OUT = { NAME: 'ALL', COUNT: out };
        else this.surveyParticipations.OUT = { NAME: 'OUT', COUNT: 0 };
        if (over) this.surveyParticipations.OVER = { NAME: 'ALL', COUNT: over };
        else this.surveyParticipations.OVER = { NAME: 'OVER', COUNT: 0 };
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  /**
   * @description: 5000 샘플 이상일 경우 결과페이지 제공하지 않음
   *  GitHub Issue #106
   */
  get sampleOver() {
    return +this.completeCount <= 5000;
  }

  initEventBus() {
    EventBus.$on(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD, () => {
      this.questionInfo = this.$question.getMakeList();
    });
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_START, () => (this.loading = true));
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_END, () => (this.loading = false));
  }

  getLoadSendData(): { SNUM: number; userId: string } {
    return {
      SNUM: this.svyNumber,
      userId: this.$store.getters.userId,
    };
  }

  changeSingleView(propSingleView: boolean) {
    this.singleView = propSingleView;
  }

  get getQuestionInfo(): IQuestionValue[] {
    return this.singleView ? this.questionInfo.filter((item) => item.QNUM === this.dataName) : this.questionInfo;
  }

  questionSwitch(QNAME) {
    const { QNUM } = QNAME;
    if (QNUM === this.dataName) return;
    this.dataName = QNUM;
    this.questionInfo = this.questionInfo.map((m) => {
      m.EDIT = false;
      return m;
    });
    const index = this.questionInfo.findIndex((f) => f.QNUM === QNUM);
    if (this.questionInfo[index].EDIT) return;
    this.questionInfo[index].EDIT = true;
    setTimeout(() => {
      const ele = document.getElementById(`view-${QNUM}`);
      if (ele) {
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
  }
  setDataName(payload: string) {
    this.dataName = payload;
  }
}
