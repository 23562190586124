






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RadioIcon extends Vue {
  @Prop() mouseHover?: boolean;
}
