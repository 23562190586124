






import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import WithdrawalInformation from '@/components/users/profile/withdrawal/WithdrawalInformation.vue';
import WithdrawalReason from '@/components/users/profile/withdrawal/WithdrawalReason.vue';

@Component({
  components: {
    WithdrawalInformation,
    WithdrawalReason,
  },
})
export default class WithdrawalModal extends Vue {
  modalType: string = 'information';

  private nextProgress(modalType: string): void {
    this.modalTypeComputed = modalType;
  }

  //모달 타입 변경
  private set modalTypeComputed(modalType: string) {
    this.modalType = modalType;
  }
  private get modalTypeComputed() {
    return this.modalType;
  }
  // 모달이 닫힐 경우 초기화면으로
  private reset(): void {
    this.modalTypeComputed = 'information';
  }

  private get dynamicComponent() {
    switch (this.modalTypeComputed) {
      case 'information': {
        return WithdrawalInformation;
      }
      case 'reason': {
        return WithdrawalReason;
      }
      case 'withdrawalEnd': {
        break;
      }
    }
  }
}
