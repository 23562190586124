






























import { Component, Ref, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({})
export default class ChangeNickname extends Vue {
  isLoading = false;
  nickName = this.$store.getters.userNickname;
  userId = this.$store.getters.userId;
  userNickname = '';

  @Ref() readonly refNickname!: HTMLElement;

  mounted() {
    setTimeout(() => {
      this.$nextTick(() => this.refNickname.focus());
    }, 300);
  }

  inputValidationCheck() {
    const regText = /^[a-zA-Zㄱ-힣0-9][a-zA-Zㄱ-힣0-9]*$(\s*)/g;
    return regText.test(this.userNickname);
  }

  private async changeNickname() {
    const check = this.inputValidationCheck();
    if (!check) {
      this.$common.makeToast(ToastMessage.CHANGE_NICKNAME_CHECK, ToastVariant.DANGER, this.$bvToast);
      return;
    }
    const sendData = {
      userId: this.userId,
      newNickname: this.userNickname,
    };
    // 동일한 이름일 경우 변경 불가능
    if (this.userNickname === this.nickName) {
      this.$common.makeToast(ToastMessage.SAME_NICKNAME, ToastVariant.DANGER, this.$bvToast);
      this.userNickname = '';
      return;
    }
    this.isLoading = true;
    const { data } = await this.axios.patch('/user/change/nickname', sendData);
    const { result } = data;
    this.isLoading = false;
    if (result) {
      this.$store.commit('changeNickname', this.userNickname);
      setTimeout(() => {
        this.$common.makeToast(ToastMessage.UPDATE, ToastVariant.SUCCESS, this.$bvToast);
      }, 100);
      await this.$router.push('/project/list');
    }
  }
}
