






import { Component, Prop, Vue } from 'vue-property-decorator';

type FontSize = '12' | '13' | '14' | '15' | '16' | '18' | '20' | '22' | '24';
type FontColor =
  | 'black'
  | 'black2'
  | 'black3'
  | 'white'
  | 'pointColor'
  | 'placeholder'
  | 'red'
  | 'textBody'
  | 'textTitle'
  | 'textSub'
  | 'gray'
  | 'gray2'
  | 'yellow';
type FontWeight = 'regular' | 'normal' | 'medium' | 'semiBold' | 'bold';
type FontType = 'gothic' | 'square';

@Component
export default class Typography extends Vue {
  @Prop({ default: 'black' }) color: FontColor;
  @Prop({ default: '12' }) size: FontSize;
  @Prop({ default: 'regular' }) weight: FontWeight;
  @Prop({ default: 'square' }) type: FontType;

  get FontType() {
    return this.type;
  }
  get FontSize() {
    return `font-${this.size}`;
  }
  get FontColor() {
    return this.color;
  }
  get FontWeight() {
    return this.weight;
  }
}
