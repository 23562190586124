































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionRandom extends Vue {
  @Prop({ required: true, default: '' }) random: string;
  @Prop({ required: false, default: '보기 순서 랜덤 제시' }) label: string;
  @Prop({ required: true }) manualType: boolean;

  radioValue: string = '';
  options: IArrayOption[] = [
    { text: '해제', value: '' },
    { text: '모두', value: 'ALL' },
    { text: '마지막 1개 보기 고정', value: '-1' },
    { text: '마지막 2개 보기 고정', value: '-2' },
  ];

  created() {
    this.radioValue = this.random;
  }

  buttonActive(value: string) {
    switch (value) {
      case '':
        return 0;
      case 'ALL':
        return 1;
      case '-1':
        return 2;
      case '-2':
        return 3;
    }
  }

  changeButton(value: string | '') {
    this.radioValue = value;
    this.$emit('set-data', this.radioValue);
  }

  private showManual(radioValue) {
    const none = require('@/assets/images/options/random-none.png');
    const all = require('@/assets/images/options/random-all.png');
    const one = require('@/assets/images/options/random-last-one.png');
    const two = require('@/assets/images/options/random-last-two.png');

    let content = '';

    switch (radioValue) {
      case '':
        content = `<div>
                    <h1 class="option-description--text">보기 내용을 작성한 순서와 상관 없이 무작위로 제시합니다.</h1>
                    <br />
                    <h2 class="option-description--text">[기타 / 없음] 등의 보기를 고정하여 제시하고 싶다면, [보기 고정] 기능을 활용할 수 있습니다.</h2>
                    <hr />
                    <b class="option-description--text-blue mt-2">선택 옵션 : [ 해제 ]</b>
                    <img alt="보기고정1" style='width: 100%;' src="${none}" />
                </div>`;
        break;
      case 'ALL':
        content = `<div>
                    <h1 class="nanumgothic-normal-fuscous-gray-14px">보기 내용을 작성한 순서와 상관 없이 무작위로 제시합니다.</h1>
                    <br />
                    <h2 class="nanumgothic-normal-fuscous-gray-14px">[기타 / 없음] 등의 보기를 고정하여 제시하고 싶다면, [보기 고정] 기능을 활용할 수 있습니다.</h2>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 모두 ]</b>
                    <img alt="보기고정2" style='width: 100%;' src="${all}" />
                </div>`;
        break;
      case '-1':
        content = `<div>
                    <h1 class="option-description--text">보기 내용을 작성한 순서와 상관 없이 무작위로 제시합니다.</h1>
                    <br />
                    <h2 class="option-description--text">[기타 / 없음] 등의 보기를 고정하여 제시하고 싶다면, [보기 고정] 기능을 활용할 수 있습니다.</h2>
                    <hr />
                    <b class="option-description--text-blue mt-2">선택 옵션 : [ 마지막 1개 보기 고정 ]</b>
                    <img alt="보기고정3" style='width: 100%;' src="${one}" />
                </div>`;
        break;
      case '-2':
        content = `<div>
                    <h1 class="option-description--text">보기 내용을 작성한 순서와 상관 없이 무작위로 제시합니다.</h1>
                    <br />
                    <h2 class="option-description--text">[기타 / 없음] 등의 보기를 고정하여 제시하고 싶다면, [보기 고정] 기능을 활용할 수 있습니다.</h2>
                    <hr />
                    <b class="option-description--text-blue mt-2">선택 옵션 : [ 마지막 2개 보기 고정 ]</b>
                    <img alt="보기고정4" style='width: 100%;' src="${two}" />
                </div>`;
        break;
    }
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: content,
    };
  }
}
