






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionRadioSet } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class RadioSet extends Vue {
  @Prop() private data: IQuestionRadioSet;
  @Prop() private questionTypeName?: string;

  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const { ANSWERS = [], RANDOM, BACKGROUND = '', H_HEAD = '', H_WIDTH = '', KEY_SHOW = false, NAME } = this.data;
    const answerBox = this.$refs['s-answer'];
    //색칠
    const colors = this.$common.makeGradient(BACKGROUND, ANSWERS.length);
    let th,
      td,
      a,
      count = 0;
    const table = document.createElement('table');
    table.classList.add('common', 'pure-table', 'pure-table-bordered', 'w-100');
    table.style.tableLayout = 'fixed';
    answerBox.appendChild(table);
    const thead = document.createElement('thead');
    table.appendChild(thead);
    let tr = document.createElement('tr');
    thead.appendChild(tr);
    //응답값 머릿말이 있는 경우
    if (H_HEAD !== '') {
      th = document.createElement('th');
      tr.appendChild(th);
      th.style.width = `${Number(H_WIDTH) * ANSWERS.length}%`;
      th.setAttribute('colspan', String(ANSWERS.length));
      th.innerHTML = H_HEAD;
      tr = document.createElement('tr');
      thead.appendChild(tr);
    }

    //값 및에 숫자 표시해야 하는 경우
    let keyTr;
    if (KEY_SHOW) {
      keyTr = document.createElement('tr');
      thead.appendChild(keyTr);
    }

    //값 머릿말 추가
    for (let a = 0; a < ANSWERS.length; a++) {
      th = document.createElement('th');
      th.classList.add('head');
      th.setAttribute('qname', NAME);
      tr.appendChild(th);
      if (colors != null) th.style.backgroundColor = colors[a];
      if (KEY_SHOW) {
        const numTh = document.createElement('th');
        numTh.classList.add('head');
        numTh.innerText = ANSWERS[a].K;
        keyTr.appendChild(numTh);
        if (colors != null) numTh.style.backgroundColor = colors[a];
      }

      th.innerHTML = ANSWERS[a].V;
      count++;
    }
    tr.querySelectorAll('th').forEach((item) => (item.style.width = `${100 / count}%`));

    //보기 추가
    const tbody = document.createElement('tbody');
    table.appendChild(tbody);
    tr = document.createElement('tr');
    tbody.appendChild(tr);

    for (let a = 0; a < ANSWERS.length; a++) {
      const aKey = ANSWERS[a].K;
      td = document.createElement('td');
      td.classList.add('answer-unit-box', 'text-center', 'td-radio');
      tr.appendChild(td);
      const label = document.createElement('label');
      label.classList.add('pure-radio');
      label.setAttribute('for', `${NAME}_${aKey}`);
      td.appendChild(label);
      const input = document.createElement('input');
      input.setAttribute('id', `${NAME}_${aKey}`);
      input.setAttribute('name', NAME);
      input.setAttribute('aKey', aKey);
      input.setAttribute('data-column', '1');
      input.setAttribute('data-next', String(ANSWERS[a].N));
      input.setAttribute('type', 'radio');
      input.value = aKey;
      label.appendChild(input);
    }
  }

  async initEvent(): Promise<void> {
    //td 부분을 클릭해도 radio가 클릭되도록 함
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const tds: NodeListOf<HTMLElement> = answerBox.querySelectorAll('td.td-radio') || [];
    for (const td of tds) {
      td.addEventListener('click', this.radioClick);
    }
  }
  radioClick(evt) {
    const { target } = evt;
    const radio = target.querySelector('input[type="radio"]');
    if (radio) {
      radio.click();
    }
  }

  beforeDestroy() {}
}
