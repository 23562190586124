



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { IHelp, BOARD } from '@/types/board';

@Component
export default class HelpLists extends Vue {
  @Prop() helpId: string;
  @Prop({ required: false, default: '' }) modalId: string;
  help: IHelp = BOARD.helpInit();

  async load() {
    try {
      const { data } = await this.axios.get(`/board/select-help/${this.helpId}`);
      const { help, result } = data;
      if (result) {
        this.help = help;
      }
    } catch (e) {
      console.log(e);
    }
  }

  categoryVariant(category: BOARD.HELP_CATEGORY): string {
    if (category === BOARD.HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === BOARD.HELP_CATEGORY.USE) return 'success';
    else return 'primary';
  }
}
