











































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import type { IQuestionValue, ITextProp } from '@/interface/survey/question';
import { RESULT } from '@/types/result';
import InfiniteLoading from 'vue-infinite-loading';
import TEXT_SORT_TYPE = RESULT.TEXT_SORT_TYPE;

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class TextTable extends Vue {
  @Prop() question: IQuestionValue;
  @Ref() InfiniteLoading: InfiniteLoading;
  private documentHeight = document.documentElement.clientHeight - 150;

  valueSortType: TEXT_SORT_TYPE = TEXT_SORT_TYPE.DESC;
  textSortType: TEXT_SORT_TYPE = TEXT_SORT_TYPE.ASC;

  infiniteId = +new Date();
  textList: ITextProp[] = [];
  currentPage: number = 1;
  perPage: number = 5;

  created(): void {
    this.textListMap();
    this.textSort();
  }

  async mounted(): Promise<void> {
    this.$common.documentTop();
    window.addEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.documentHeight = this.$common.documentHeight;
    this.textListMap();
    this.textSort();
  }

  textListMap() {
    if (this.question.TEXT_LIST?.length) {
      this.textList = [...this.question.TEXT_LIST];
    }
  }

  get textButtonWord(): string {
    const isAsc = this.textSortType === TEXT_SORT_TYPE.ASC;
    return isAsc ? 'Z-A' : 'A-Z';
  }

  get valueButtonWord(): string {
    const isAsc = this.valueSortType === TEXT_SORT_TYPE.ASC;
    return isAsc ? 'arrow-up' : 'arrow-down';
  }

  get pageList(): ITextProp[] {
    const startIdx = (this.currentPage - 1) * this.resizePerPage;
    const endIdx = startIdx + this.resizePerPage;
    return this.textList.slice(startIdx, endIdx);
  }

  textSort() {
    this.textList = this.textList.sort((a, b) => {
      if (a.cnt > b.cnt) return -1 * this.getValueSortValue();
      if (a.cnt < b.cnt) return this.getValueSortValue();
      if (a.text > b.text) return this.getTextSortValue();
      if (a.text < b.text) return -1 * this.getTextSortValue();
      return 0;
    });
  }

  get scrollHeight(): number {
    const isOver = this.documentHeight >= 1000;
    return isOver ? 600 : 600;
  }

  get resizePerPage(): number {
    const isOver = this.documentHeight >= 1000;
    return isOver ? 20 : 10;
  }

  getIdx(idx: number): number {
    const defaultSet = idx + 1;
    const startIdx = (this.currentPage - 1) * this.resizePerPage;
    return defaultSet + startIdx;
  }

  getValueSortValue(): number {
    if (this.valueSortType === TEXT_SORT_TYPE.DESC) return 1;
    else return -1;
  }

  getTextSortValue(): number {
    if (this.textSortType === TEXT_SORT_TYPE.ASC) return 1;
    else return -1;
  }

  get getQuestionType(): string {
    return this.question.TYPE;
  }

  get isMulti(): boolean {
    return this.getQuestionType === 'MULTI_TEXT';
  }

  reSortText(): void {
    const isAsc = this.textSortType === TEXT_SORT_TYPE.ASC;
    if (isAsc) this.textSortType = TEXT_SORT_TYPE.DESC;
    else this.textSortType = TEXT_SORT_TYPE.ASC;
    this.textSort();
  }

  reSortValue(): void {
    const isAsc = this.valueSortType === TEXT_SORT_TYPE.ASC;
    if (isAsc) this.valueSortType = TEXT_SORT_TYPE.DESC;
    else this.valueSortType = TEXT_SORT_TYPE.ASC;
    this.textSort();
  }
}
