var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"create-complete",attrs:{"question-name":_vm.data.NAME,"question-type":_vm.data.TYPE,"data-page_end":_vm.data.PAGE_END,"data-type":_vm.data.TYPE,"data-qnum":_vm.data.QUESTION,"data-name":_vm.data.NAME,"data-question":_vm.data.QUESTION,"qname":_vm.data.NAME,"random-result":_vm.answers
      .map(function (a) {
        return a.K;
      })
      .join(','),"random-h-result":_vm.hAnswer
      .map(function (a) {
        return a.K;
      })
      .join(','),"data-min-check":_vm.data.MIN_CHECK || '1',"min-check":_vm.data.MIN_CHECK || '1',"max-check":_vm.data.MAX_CHECK || '1'}},[_c('QuestionHtml',{attrs:{"questionTypeName":_vm.questionTypeName,"QNUM":_vm.data.QNUM,"QUESTION":_vm.data.QUESTION,"DESC":_vm.data.DESC}}),_c('div',{ref:"s-answer",staticClass:"s-answer question-content-body-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }