














import { Component, Vue } from 'vue-property-decorator';
import CreateNewProject from '@/components/main/create/CreateNewProject.vue';
import CreateNewTemplate from '@/components/main/create/CreateNewTemplate.vue';
import CreateNewProjectTest from '@/components/main/create/CreateNewProjectTest.vue';
import { IQuotaResetOption, IQuotaValue } from '@/interface/survey/quota';
import { QUOTA } from '@/types/quota';
import CreateNewProjectModal from '@/components/main/create/CreateNewProjectModal.vue';
import CreateNewProjectResetButton from '@/components/main/create/button/CreateNewProjectResetButton.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    CreateNewProjectResetButton,
    CreateNewProjectModal,
    CreateNewProject,
    CreateNewTemplate,
    CreateNewProjectTest,
  },
})
export default class NewProject extends Vue {
  showing: boolean = false;
  setFlag: string = '1';
  viewKey: number = 0;
  settingQuota: IQuotaValue = {
    RESPONSE_CNT: 0,
    GENDER: '',
    AGE_UNIT: '',
    AGE_10: [],
    AGE_5: [],
    QUOTA_SET: '',
    QUOTA_CNT: QUOTA.quotaCntInit(),
    AREA: [],
  };
  loading: boolean;
  disabled: boolean;
  resetOption: IQuotaResetOption = QUOTA.resetSettingLoad();

  constructor() {
    super();
    this.loading = false;
    this.disabled = false;
  }

  created(): void {
    this.showing = true;
    const { params } = this.$route;
    const { id } = params;
    this.setFlag = id;
    this.showing = false;
  }

  setQuotaData(quotaData: IQuotaValue) {
    this.settingQuota = quotaData;
    this.createNewProject();
  }

  async createNewProject(): Promise<void> {
    try {
      const createType = Number(this.$route.params.id); // 1: pmi 패널 대상 2:리스트 대상(알아서)
      const sendData = {
        userId: this.$store.getters.userId,
        TITLE: '',
        PLACEHOLDER: '설문지 제목 입력',
        QUOTA: {
          RESPONSE_CNT: this.settingQuota?.RESPONSE_CNT,
          GENDER: this.settingQuota?.GENDER,
          AGE_UNIT: this.settingQuota?.AGE_UNIT,
          AGE_10: this.settingQuota?.AGE_10,
          AGE_5: this.settingQuota?.AGE_5,
          QUOTA_SET: this.settingQuota?.QUOTA_SET,
          QUOTA_CNT: this.settingQuota?.QUOTA_CNT,
          AREA: this.settingQuota?.AREA,
        },
        createType: createType,
      };
      const { data } = await this.axios({
        url: '/project/new',
        method: 'POST',
        data: sendData,
      });

      const { SNUM, result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.CREATE_PROJECT, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.push(`/project/make/${SNUM}`);
      }
    } catch (e) {
      console.log(e);
    }
  }
}
