










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LinkIcon extends Vue {
  @Prop() type?: boolean;
}
