import { render, staticRenderFns } from "./Check.vue?vue&type=template&id=87ed9b2a&scoped=true&"
import script from "./Check.vue?vue&type=script&lang=ts&"
export * from "./Check.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87ed9b2a",
  null
  
)

export default component.exports