







import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class IdIcon extends Vue {
  @Prop({default: false}) idFocus: boolean;
}
