

























import {Component, Prop, Vue} from "vue-property-decorator";

// #A1A1A1 | #C1C1C1
type Color = 'white' | 'black' | 'gray';
type LogoType = 'normal' | 'footer';

@Component({})
export default class UnisurveyLogo extends Vue {
  @Prop({default: 'white'}) color?: Color;
  @Prop({default: '234'}) width?: string;
  @Prop({default: '41'}) height?: string;
  @Prop({default: 'normal'}) type?: LogoType;
  get LogoColor() {
    switch (this.color) {
      case 'white':
        return '#fff';
      case 'black':
        return '#000';
      case 'gray':
        return '#A1A1A1';
      default:
        return '#fff'
    }
  }

  get LogoType() {
    switch (this.type) {
      case 'normal':
        return {
          partOne: '#3CB5E5',
          partTwo: '#FF8033',
        };
      case 'footer':
        return {
          partOne: '#A1A1A1',
          partTwo: '#C1C1C1',
        };
      default:
        return {
          partOne: '#3CB5E5',
          partTwo: '#FF8033',
        };
    }
  }
}
