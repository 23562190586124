











































import PreviewTemplateModal from '@/components/main/PreviewTemplateModal.vue';
import { Component, Vue } from 'vue-property-decorator';
import { ITemplateFavorite } from '@/interface/template/template';
import { TEMPLATE } from '@/types/template';
import { IReloadDto } from '@/interface/common';
import CreateNewProjectModal from '@/components/main/create/CreateNewProjectModal.vue';

@Component({
  components: {
    PreviewTemplateModal,
    CreateNewProjectModal,
  },
})
export default class TemplateSideList extends Vue {
  templateList: TEMPLATE.FavoriteList = [];
  loading: boolean = true;
  pageNum: number = 1;
  parPage: number = 10;
  modalFlag: string = 'side';

  async created() {
    await this.load();
  }

  async load(): Promise<void> {
    try {
      this.loading = true;
      const sendData = this.createSendData();
      const { data } = await this.axios({
        url: '/template/book-mark',
        method: 'GET',
        params: sendData,
      });
      const { getData } = data as { getData: TEMPLATE.List };
      if (getData.length > 0) this.templateList = getData;
      else this.templateList = [];
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  createSendData(): IReloadDto {
    return {
      pageNum: this.pageNum,
      parPage: this.parPage,
    };
  }

  sendTemplate(data: ITemplateFavorite): void {
    this.$bvModal.show(`preview-template-modal-${this.modalFlag}-${data._id}`);
  }
}
