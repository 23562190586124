






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import eventBus from '@/utils/EventBus';

@Component({})
export default class CreateNewProjectResetButtonModal extends Vue {
  confirm() {
    eventBus.$emit('modal-confirm');
  }

  cancel() {
    this.$bvModal.hide('create-project-reset-modal');
  }
}
