


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { QUESTION } from '@/types/question';
import { STATUS_BUTTON, STATUS_LABEL, STATUS_MESSAGE } from '@/types/constant';
import LINK_STATUS = QUESTION.LINK_STATUS;

@Component
export default class ProgressStatus extends Vue {
  @Prop() linkStatus: number;
  @Prop() projectId: number;
  @Prop({ default: '' }) comment: string;

  label: string = '';
  contents: string = '';
  contents2: string = '';
  buttonLabel: string = '';

  mounted() {
    this.paymentLabel();
  }

  get showButton(): boolean {
    return (
      this.linkStatus === LINK_STATUS.FW_START ||
      this.linkStatus === LINK_STATUS.FW_END ||
      this.linkStatus === LINK_STATUS.FW_PAUSE ||
      this.linkStatus === LINK_STATUS.FW_CANCEL ||
      this.linkStatus === LINK_STATUS.FW_SHUT_DOWN ||
      this.linkStatus === LINK_STATUS.REJECT
    );
  }

  paymentLabel(): void {
    switch (this.linkStatus) {
      case QUESTION.LINK_STATUS.SAMPLE:
        this.label = STATUS_LABEL.EMPTY;
        this.contents = STATUS_MESSAGE.SAMPLE;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        break;
      case QUESTION.LINK_STATUS.REVIEW_HOLD:
        this.label = STATUS_LABEL.REVIEW_HOLD;
        this.contents = STATUS_MESSAGE.PAID;
        this.contents2 = STATUS_MESSAGE.CONFIRM;
        break;
      case QUESTION.LINK_STATUS.REVIEW:
      case QUESTION.LINK_STATUS.RE_REVIEW_DONE:
        this.label = STATUS_LABEL.REVIEW;
        this.contents = STATUS_MESSAGE.REVIEW;
        this.contents2 = STATUS_MESSAGE.NOT_YET_REVIEW;
        break;
      case QUESTION.LINK_STATUS.REVIEW_DONE:
        this.label = STATUS_LABEL.REVIEW_DONE;
        this.contents = STATUS_MESSAGE.REVIEW;
        this.contents2 = STATUS_MESSAGE.WILL_SEND_LINK;
        break;
      case QUESTION.LINK_STATUS.FW_START:
        this.label = STATUS_LABEL.FW_START;
        this.contents = STATUS_MESSAGE.SEND_LINK;
        this.contents2 = STATUS_MESSAGE.AUTO_SHUTDOWN;
        this.buttonLabel = STATUS_BUTTON.QUOTA;
        break;
      case QUESTION.LINK_STATUS.FW_PAUSE:
        this.label = STATUS_LABEL.FW_PAUSE;
        this.contents = STATUS_MESSAGE.PAUSE;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        this.buttonLabel = STATUS_BUTTON.QUOTA;
        break;
      case QUESTION.LINK_STATUS.FW_END:
        this.label = STATUS_LABEL.FW_END;
        this.contents = STATUS_MESSAGE.END;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        this.buttonLabel = STATUS_BUTTON.RESULT;
        break;
      case QUESTION.LINK_STATUS.FW_CANCEL:
        this.label = STATUS_LABEL.FW_CANCEL;
        this.contents = STATUS_MESSAGE.CANCEL;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        this.buttonLabel = STATUS_BUTTON.RESULT;
        break;
      case QUESTION.LINK_STATUS.FW_SHUT_DOWN:
        this.label = STATUS_LABEL.FW_SHUT_DOWN;
        this.contents = STATUS_MESSAGE.SHUTDOWN;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        this.buttonLabel = STATUS_BUTTON.RESULT;
        break;
      case QUESTION.LINK_STATUS.REJECT:
        this.label = STATUS_LABEL.REJECT;
        this.contents = STATUS_MESSAGE.INCORRECT_QUESTION;
        this.contents2 = STATUS_MESSAGE.REVIEW_REQUEST;
        this.buttonLabel = STATUS_BUTTON.REJECT;
        break;
      default:
        this.label = STATUS_LABEL.EMPTY;
        this.contents = STATUS_MESSAGE.EMPTY;
        this.contents2 = STATUS_MESSAGE.EMPTY;
        this.buttonLabel = STATUS_LABEL.EMPTY;
        break;
    }
  }

  redirectQuotaPage() {
    switch (this.linkStatus) {
      case QUESTION.LINK_STATUS.FW_START:
      case QUESTION.LINK_STATUS.FW_PAUSE:
        this.$router.push(`/project/quota/${this.projectId}`);
        break;
      case QUESTION.LINK_STATUS.FW_END:
      case QUESTION.LINK_STATUS.FW_SHUT_DOWN:
      case QUESTION.LINK_STATUS.FW_CANCEL:
        this.$router.push(`/project/result/${this.projectId}`);
        break;
      case QUESTION.LINK_STATUS.REJECT:
        this.inspection();
        break;
    }
  }

  inspection() {
    this.$emit('inspectionModal');
  }
}
