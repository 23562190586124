






































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { USER } from '@/types/user';
import { IUserLogin } from '@/interface/user/user';
import { STYLE } from '@/interface/style';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import PasswordIcon from "@/components/icons/login/PasswordIcon.vue";
import IdIcon from "@/components/icons/login/IdIcon.vue";
import KakaoLogin from "@/components/users/sign-in/oauth/KakaoLogin.vue";
import NaverLogin from "@/components/users/sign-in/oauth/NaverLogin.vue";
import UnisurveyLogo from "@/components/icons/common/UnisurveyLogo.vue";
import EyeIcon from "@/components/icons/common/EyeIcon.vue";
import SignUpSuccessModal from "@/components/users/sign-up/SignUpSuccessModal.vue";

@Component({
  components: {
    EyeIcon,
    NaverLogin,
    KakaoLogin,
    SignUpSuccessModal,
    IdIcon,
    PasswordIcon,
    UnisurveyLogo
  },
})
export default class LoginComponent extends Vue {
  @Ref() idInput: HTMLElement;
  @Ref() passwordInput: HTMLElement;

  saveId = false;
  showPassword = false;
  isLoading = false;
  loginInfo: IUserLogin = USER.LoginInit();
  failCount = 0;
  idFocus = false;
  passwordFocus = false;
  boardQuestionId = '';
  //===========  STYLE  ===========//
  idActive = STYLE.ID_FOCUS;
  passwordActive = STYLE.PW_FOCUS;
  idFocusInput = STYLE.ID_FOCUS_INPUT;
  pwFocusInput = STYLE.PW_FOCUS_INPUT;
  svgActive = STYLE.LOGIN_SVG_ICON;

  created() {
    this.cookieCheck();
  }

  mounted() {
    const { success, listId } = this.$route.query as { success: string, listId: string };
    if (success && success === 'true') this.$bvModal.show('join-success');
    // 아이디가 존재할 경우 문의 게시판으로 이동
    if (listId && listId.length > 0) this.boardQuestionId = listId;
  }

  cookieCheck() {
    const getIdByCookie = this.$cookies.get('userId');
    if (getIdByCookie) {
      this.saveId = true;
      this.loginInfo.userId = getIdByCookie;
    }
    return getIdByCookie;
  }

  async login(): Promise<void> {
    try {
      if (!this.idValidation()) return;
      if (!this.pwdValidation()) return;
      this.isLoading = true
      const { userId, userPwd } = this.createSendData();

      const idEncrypted = this.$common.rsaEncrypt(userId);
      const pwEncrypted = this.$common.rsaEncrypt(userPwd);
      const sendData = {
        userId: idEncrypted,
        userPwd: pwEncrypted
      }

      // const historyData = await this.loginHistoryCheck(idEncrypted);
      // const {ACCESS, HISTORY_ID, TOKEN} = historyData
      // if (ACCESS) {
      //   const check = confirm('다른 브라우저에서 사용중 입니다. 다시 로그인 하시겠습니까?');
      //   if (check) {
      //     await this.$store.dispatch('socketStore/loginCheck', {userId,TOKEN});
      //     await this.logoutOfAnotherAccount(HISTORY_ID);
      //   } else {
      //     return;
      //   }
      // }

      const { data } = await this.axios({
        url: `/user/login`,
        method: 'POST',
        data: sendData,
      });

      const { result, ACCOUNT_LOCK, UNLOCK_DATE } = data;
      // if (result) {
      //   if (ACCOUNT_LOCK) {
      //     const currentDate = new Date().getTime();
      //     const unlockDate = new Date(UNLOCK_DATE).getTime();
      //     const unlock = currentDate - unlockDate
      //     if (unlock >= 0) {
      //       await this.axios.patch('/user/account-unlock', {USER: userId})
      //       await this.login();
      //     } else {
      //       alert('로그인 정보를 5회 이상 잘못 입력하셨습니다. 5분 후 다시 시도해 주세요.')
      //     }
      //   } else {
      //     await this.userDataSaveInStore(data);
      //   }
      // } else {
      //   this.inputClear();
      //   this.idInput.focus();
      //   this.failCount++;
      //   this.failCheck();
      // }
      if (result) await this.userDataSaveInStore(data);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  saveIdByCookie() {
    this.saveId = !this.saveId;
    if (this.saveId && this.loginInfo.userId.length > 0) {
      this.$cookies.set('userId', this.loginInfo.userId);
    } else {
      this.$cookies.remove('userId');
    }
  }

  async userDataSaveInStore(data) {
    await this.$store.dispatch('login', { data });

    // 문의 아이디가 존재할 경우 문의글로 넘겨주기
    if (this.boardQuestionId.length > 0) await this.$router.push(`/board/question-read/${this.boardQuestionId}`)
    else await this.$router.push('/project/list');
  }

  inputClear() {
    this.loginInfo.userId = '';
    this.loginInfo.userPwd = '';
  }

  async loginHistoryCheck(userId: string) {
    const historyData = {
      ACCESS: false,
      HISTORY_ID: '',
      TOKEN: '',
    }
    const {data} = await this.axios.post('/user/login-history-check',{userId});
    if (data) {
      const {ACCESS, _id, TOKEN} = data;
      historyData.ACCESS = ACCESS;
      historyData.HISTORY_ID = _id;
      historyData.TOKEN = TOKEN;
    }
    return historyData;
  }

  async logoutOfAnotherAccount(_id: string): Promise<boolean> {
    const {data} = await this.axios.put('/user/login-history/logout',{_id});
    const {historyUpdate} = data;
    return historyUpdate;
  }

  get getToken() {
    return this.$store.getters['token'];
  }

  idValidation(): boolean {
    const isIdNull = !this.loginInfo.userId;
    if (isIdNull) {
      this.idInput.focus();
      this.$common.makeToast(ToastMessage.EMPTY_ID, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  pwdValidation(): boolean {
    const isPwNull = !this.loginInfo.userPwd;
    if (isPwNull) {
      this.passwordInput.focus();
      this.$common.makeToast(ToastMessage.EMPTY_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  failCountInit(): void {
    this.failCount = 0;
  }

  failCheck(): boolean {
    const overTry = this.failCount >= 5;
    if (overTry) {
      setTimeout(this.failCountInit, 10000);
      return false;
    }
    return true;
  }

  createSendData(): IUserLogin {
    const { userId, userPwd } = this.loginInfo;
    return {
      userId,
      userPwd,
    };
  }

  mailResend() {
    if (this.loginInfo.userId.length <= 0 || this.loginInfo.userPwd.length <= 0)
      this.$common.makeToast(ToastMessage.LOGIN_MESSAGE, ToastVariant.DANGER, this.$bvToast);
    else this.$bvModal.show('email-resend-modal');
  }

  get idState(): boolean | null {
    if (!this.loginInfo.userId) return null;
    return this.loginInfo.userId.length > 0;
  }

  get passwordState(): boolean | null {
    if (!this.loginInfo.userPwd) return null;
    return this.loginInfo.userPwd.length >= 8;
  }

  get failState(): boolean {
    return this.failCount >= 5;
  }
}
