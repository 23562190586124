import { render, staticRenderFns } from "./HelpLists.vue?vue&type=template&id=5f1abde2&scoped=true&"
import script from "./HelpLists.vue?vue&type=script&lang=ts&"
export * from "./HelpLists.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1abde2",
  null
  
)

export default component.exports