






























































































































































































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import type { ISurveyContent, ISurveyValue } from '@/interface/survey/question';
import {QUESTION} from '@/types/question';
import { ins as axios } from '@/utils/axios';
import { IProjectConfig, IQuestionValue } from '@/interface/survey/question';
import EventBus from '@/utils/EventBus';
import { ProjectDeleteModal, MakeTemplateModal, MySurveyUpdateModal } from '@/components/modal/project';
import CreateNewProjectModal from '@/components/main/create/CreateNewProjectModal.vue';
import Typography from '@/components/common/typography/Typography.vue';
import SearchIcon from "@/components/icons/common/SearchIcon.vue";
import MenuIcon from "@/components/icons/project/MenuIcon.vue";
import ProjectIcon from "@/components/icons/project/ProjectIcon.vue";
import ProjectCopy from "@/components/icons/project/ProjectCopy.vue";
import ResultIcon from "@/components/icons/project/ResultIcon.vue";

@Component({
  components: {
    ResultIcon,
    ProjectCopy,
    ProjectIcon,
    MenuIcon,
    SearchIcon,
    Typography,
    MakeTemplateModal,
    MySurveyUpdateModal,
    ProjectDeleteModal,
    CreateNewProjectModal },
})
export default class SurveyContent extends Vue {
  @Prop({ default: 0 }) readonly height: number;

  @Ref() readonly templateCategory!: HTMLElement;
  @Ref() readonly templateTotalTime!: HTMLElement;
  @Ref() readonly templateTotalQuestion!: HTMLElement;

  flag: number = 1;
  search: string = '';
  localList: Array<ISurveyValue> = [];
  sort: boolean = true;
  status: QUESTION.SVY_STATUS_TYPE | '' = '';
  statusOptions: IArrayOption[] = [{ text: '전체', value: '' }, ...QUESTION.SVY_STATUS_OPTION];
  QUESTION: Object = QUESTION;
  moreButtonActive: boolean = false;
  $refs: Vue['$refs'] & {
    localList: Array<ISurveyContent>;
  };
  projectLists: number = -1;
  snum: number = 0;
  removeProjectData: ISurveyValue | '' = '';
  //관리자 전용 템플릿 등록 변수
  isAdmin: string = this.$store.getters.isAdmin;
  templateTitle: string = '';
  templateInfo: Array<IQuestionValue> = [];
  templateConfig: IProjectConfig | '' = '';

  // 아이콘 옵션
  searchButtonHover = false;

  limit: number = 9; // 템플릿이 없어져서 6 -> 9로 늘림
  pageNum: number = 1;
  currentPage: number = 1;
  totalPage: number = 0;

  loading: boolean = false;
  buttonLoading: boolean = false;

  async created() {
    this.loading = true;
    await this.searchSurveyList();
    this.loading = false;
  }

  createSendData() {
    return {
      userId: this.$store.getters.userId,
      searchWord: this.search,
      pageNum: this.pageNum,
      status: this.status,
      parPage: this.limit,
    };
  }

  get activeSearchButton() {
    return this.searchButtonHover;
  }

  set activeSearchButton(payload: boolean) {
    this.searchButtonHover = payload;
  }

  // 더 보기에 담기
  get mySurveyComputed() {
    return this.localList;
  }

  set mySurveyComputed(arr: ISurveyContent[]) {
    this.localList.push(...arr);
  }

  moreSurvey(): void {
    this.pageNum++;
    this.currentPage++;
    this.searchSurveyList();
  }

  searchSurvey(): void {
    if (!this.loading) {
      this.pageNum = 1;
      this.currentPage = 1;
      this.localList = [];
      this.searchSurveyList();
      this.recentEditSurveyReload();
    }
  }

  async searchSurveyList(): Promise<void> {
    this.buttonLoading = true;
    this.loading = true;
    try {
      const sendData = this.createSendData();
      const { data } = await this.axios.post('/project/list', sendData);
      const { result, totalPage } = data;

      if (result.length > 0) {
        this.mySurveyComputed = result;
        this.totalPage = totalPage;
      }
    } catch (e) {
      console.error(e);
    }
    this.$nextTick(() => {
      this.buttonLoading = false;
      this.loading = false;
    });
  }

  statusLabelType(STATUS: string): string {
    const status = STATUS as QUESTION.SVY_STATUS_TYPE;
    if (status === QUESTION.SVY_STATUS_TYPE.OPEN) return 'open-label';
    if (status === QUESTION.SVY_STATUS_TYPE.END) return 'end-label'
    return '';
  }

  statusTranslator(CONFIG): string {
    const {LINK_STATUS, STATUS} = CONFIG;
    if (LINK_STATUS === 3) return '반려'
    const option: IArrayOption = QUESTION.SVY_STATUS_OPTION?.find(
      (option: IArrayOption) => option.value === STATUS
    ) || {
      value: '',
      text: '대기',
    };
    const { text } = option;
    return text;
  }

  KSTDate(value: Date): string {
    const timeValue = new Date(value.toLocaleString());
    const utc = timeValue.getTime() + timeValue.getTimezoneOffset() * 60 * 1000;
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    const kr_curr = new Date(utc + KR_TIME_DIFF);
    return kr_curr.toLocaleString();
  }

  openProject(id: number): void {
    this.loading = true;
    this.$router.push(`/project/make/${id}`);
  }

  openProjectResult(id: number): void {
    this.loading = true;
    this.$router.push(`/project/result/${id}`);
    this.loading = false;
  }

  surveySort(): void {
    this.localList.sort((a, b) => {
      const aTitle = a.TITLE.toLowerCase();
      const bTitle = b.TITLE.toLowerCase();
      return this.sort ? (aTitle > bTitle ? 1 : -1) : aTitle < bTitle ? 1 : -1;
    });
    this.sort = !this.sort;
  }

  async openModal(index: number, type: string): Promise<void> {
    const survey: ISurveyValue = this.localList[index];
    const sendData = {
      survey,
      type,
    };
    await this.$bvModal.show('question-copy-modal');
    EventBus.$emit('surveyData', sendData);
  }

  isLink(surveyInfo: ISurveyValue): boolean {
    if (surveyInfo.CONFIG.LINK_STATUS === undefined) return false;
    return surveyInfo.CONFIG.LINK_STATUS > QUESTION.LINK_STATUS.TEST;
  }

  get removeData() {
    return this.removeProjectData;
  }

  set removeData(data: any) {
    this.removeProjectData = data;
  }

  /**
   * 프로젝트 삭제
   * @param index
   */
  async questionRemove(index: number) {
    this.removeData = this.localList[index];
    setTimeout(() => {
      this.$bvModal.show('project-delete-modal');
    }, 200);
  }

  reload(type: boolean) {
    if (type) {
      this.searchSurvey();
      this.recentEditSurveyReload();
    }
  }

  // 내 조사 편집시 편집조사 리스트 리로드
  recentEditSurveyReload() {
    EventBus.$emit('reloadRecentEditSurvey', true);
  }

  /**
   * 템플릿 등록 ( 관리자 기능 )
   * @param index
   */
  openRegistrationModal(index: number) {
    const survey: ISurveyValue = this.localList[index];
    const { TITLE, DATA, CONFIG } = survey;
    this.templateTitle = TITLE;
    this.templateConfig = CONFIG;
    this.templateInfo = DATA;
    this.$bvModal.show('sampling-template-registration-modal');
  }

  async openMake(index) {
    try {
      const { SNUM } = this.localList[index];
      const { data } = await axios.get(`/user/verify`, {
        params: { login: 'login' },
      });
      const { token } = data;
      window.open(`/project/new-make/${token}?sNum=${SNUM}`);
    } catch (e) {
      console.log(e);
    }
  }
}
