














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionBtnHide extends Vue {
  @Prop({ required: true, default: '' }) btnHide: string;
  radioValue: string = '';
  label: string = '강제 노출 시간';

  options: IArrayOption[] = [
    { text: '없음', value: '' },
    { text: '5초', value: '5000' },
    { text: '10초', value: '10000' },
  ];

  created() {
    this.radioValue = this.btnHide;
  }

  answerCheckChange(type: string) {
    switch (type) {
      case 'none':
        this.radioValue = '';
        break;
      case '5':
        this.radioValue = '5000';
        break;
      case '10':
        this.radioValue = '10000';
        break;
      default:
        this.radioValue = 'none';
        break;
    }
    this.$emit('set-data', this.radioValue);
  }

  private showManual() {
    const url = require('@/assets/images/options/required-response.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'top',
      content: `<div>
                    <h1 class="option-description--text"><b>[5초 / 10초]</b> 선택 시, 응답자는 해당 문항에서 설정한 시간 지난 이후에 다음 문항으로 넘어갈 수 있습니다.</h1>
                </div>`,
    };
  }
}
