import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=660da7e6&scoped=true&"
import script from "./ProjectList.vue?vue&type=script&lang=ts&"
export * from "./ProjectList.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=660da7e6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "660da7e6",
  null
  
)

export default component.exports