


































































































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import type { IProjectConfig, IProjectValue } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';
import EventBus from '@/utils/EventBus';
import { BFormFile } from 'bootstrap-vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class ThemeOption extends Vue {
  @Prop({ required: true }) private config: IProjectConfig;
  isLoading: boolean = false;
  fontOption: Array<IArrayOption> = [
    { text: '맑은고딕', value: 'MalgunGothic' },
    { text: '나눔고딕', value: 'NanumGothic' },
  ];
  skinOption: Array<IArrayOption> = [
    { text: '기본', value: 'DEFAULT' },
    { text: 'ICT', value: 'ICT' },
    { text: 'PMI', value: 'PMI' },
    { text: 'PMI2', value: 'PMI2' },
  ];
  logoPositionOption: Array<IArrayOption> = [
    { text: '보여주지 않음', value: 'HIDDEN' },
    { text: '왼쪽', value: 'LEFT' },
    { text: '가운데', value: 'CENTER' },
    { text: '오른쪽', value: 'RIGHT' },
  ];
  logoSizeOption: Array<IArrayOption> = [
    { text: '기본', value: '' },
    { text: '원본 크기', value: 'SRC' },
    { text: '높이 고정', value: 'FIXED_HEIGHT' },
  ];
  fontControlOption: IArrayOption[] = [
    { text: '보여줌', value: true },
    { text: '보여주지 않음', value: false },
  ];
  modalId: string = 'theme-option-modal';
  $refs: Vue['$refs'] & {
    'logo-file': BFormFile;
  };

  logoSize: number = -1;
  logoIndex: number = -1;

  created(): void {
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_START, () => (this.isLoading = true));
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_END, () => (this.isLoading = false));
  }

  textNumberCheck(number: string) {
    const text = +this.$common.replaceOnlyNumber(number)
    if (text > 20) return 20
    else if (text <= 0) return 1;
    else return text;
  }

  logoSizeClass(type: string) {
    if (this.config.LOGO.SCALE !== 'FIXED_HEIGHT') {
      this.config.LOGO.HEIGHT = '';
    }
    return this.config.LOGO.SCALE === type;
  }

  logoLocationClass(type: string) {
    return this.config.LOGO.POSITION === type;
  }

  logoSizeSetup(value: string) {
    switch (value) {
      case '':
        this.config.LOGO.SCALE = '';
        break;
      case 'SRC':
        this.config.LOGO.SCALE = 'SRC';
        break;
      case 'FIXED_HEIGHT':
        this.config.LOGO.SCALE = 'FIXED_HEIGHT';
        break;
      default:
        this.config.LOGO.SCALE = '';
        break;
    }
  }
  logoLocationSetup(value: string) {
    switch (value) {
      case 'HIDDEN':
        this.config.LOGO.POSITION = 'HIDDEN';
        break;
      case 'LEFT':
        this.config.LOGO.POSITION = 'LEFT';
        break;
      case 'CENTER':
        this.config.LOGO.POSITION = 'CENTER';
        break;
      case 'RIGHT':
        this.config.LOGO.POSITION = 'RIGHT';
        break;
      default:
        this.config.LOGO.POSITION = 'HIDDEN';
        break;
    }
  }

  sizeControl(type: boolean) {
    this.config.FONTSIZE_CONTROL = type;
  }

  @Emit('save')
  themeOptionSave() {
    this.isLoading = true;
    return this.config;
  }

  open(): void {
    this.$bvModal.show(this.modalId);
  }

  close(): void {
    this.$bvModal.hide(this.modalId);
  }

  async logoUpload(evt) {
    try {
      this.isLoading = true;
      const { target } = evt;
      const { files = null } = target;
      const formData: FormData = new FormData();
      formData.append('logoFile', files[0]);
      const { data } = await this.axios({
        url: `/project/info/logo-upload/${this.$question.getId}`,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const { result, url } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.LOGO_UPLOAD_SUCCESS, ToastVariant.SUCCESS, this.$bvToast);
        if (this.config.LOGO.POSITION === 'HIDDEN') this.config.LOGO.POSITION = 'CENTER';
        this.config.LOGO.URL = url;
      }
    } catch (e) {
      console.log(e);
      this.$refs['logo-file'].reset();
    } finally {
      this.isLoading = false;
    }
  }
  replaceFontSize(fontSize){
    if(fontSize<1){
      return 1;
    }else if (fontSize > 50){
      return 50;
    }else{
      return fontSize;
    }

  }
}
