


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';

@Component({
  components: {
    TextBoxModal,
  },
})
export default class OptionAnswerCheck extends Vue {
  @Prop({ required: true, default: true }) answerCheck: boolean;

  options: IArrayOption[] = [
    { text: '필수', value: true },
    { text: '스킵 가능', value: false },
  ];
  radioValue: boolean = false;
  label: string = '필수 응답';

  title: string = '';
  description: string = '';

  get titleComputed() {
    return this.title;
  }

  get descriptionComputed() {
    return this.description;
  }

  set titleComputed(text: string) {
    this.title = text;
  }

  set descriptionComputed(text: string) {
    this.description = text;
  }

  created() {
    this.radioValue = this.answerCheck;
  }

  private showManual() {
    const url = require('@/assets/images/options/required-response.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>[필수]</b>는 필수로 응답해야하는 문항을 지정하는 기능입니다.</h1><br/>
                    <h2 class="option-description--text">무응답을 허용하려면 <b>[스킵 가능]</b>을 선택해 주세요.</h2>
                    <hr />
                    <img alt="필수응답" style='width: 95%; padding-left: 25px;' src="${url}" />
                </div>`,
    };
  }

  answerCheckChange() {
    this.radioValue = !this.radioValue;
    this.$emit('set-data', this.radioValue);
  }
}
