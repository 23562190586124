







































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({})
export default class ChangePassword extends Vue {
  @Ref() readonly refPassword!: HTMLElement;
  @Ref() readonly refNewPassword!: HTMLElement;

  isLoading = false;

  userId = this.$store.getters.userId;
  showCurrentPassword = false;
  showChangePassword = false;
  showChangePasswordCheck = false;

  currentPwd = '';
  changePwd = '';
  changePwdCheck = '';

  mounted() {
    setTimeout(() => {
      this.$nextTick(() => this.refPassword.focus());
    }, 300);
  }

  private async changePassword() {
    if (!this.validPassword()) return;
    const passwordCheck = await this.currentPasswordCheck();
    this.isLoading = true;
    if (passwordCheck) {
      const sendData = {
        userId: this.userId,
        userPwd: this.changePwd,
      };
      const { data } = await this.axios.post('/user/set-new-password', sendData);
      const { result, message } = data;
      if (result) {
        await this.$common.makeToast(message, ToastVariant.SUCCESS, this.$bvToast);
        this.isLoading = true;
        setTimeout(async () => {
          await this.$store.dispatch('logOut');
          await this.$router.push('/login');
        }, 400);
      }
    } else {
      this.currentPwd = '';
      this.changePwd = '';
      this.changePwdCheck = '';
      this.$nextTick(() => this.refPassword.focus());
    }
  }

  private async currentPasswordCheck(): Promise<boolean> {
    const idEncrypted = this.$common.rsaEncrypt(this.userId);
    const pwEncrypted = this.$common.rsaEncrypt(this.currentPwd);
    const sendData = {
      userId: idEncrypted,
      userPwd: pwEncrypted
    }

    const { data } = await this.axios.post('/user/login', sendData);
    const { result } = data;
    return result;
  }

  // 비밀번호 상태값 변경 - 작성한 비밀번호 미리보기
  private currentPassword() {
    this.showCurrentPassword = !this.showCurrentPassword;
  }
  private newPassword() {
    this.showChangePassword = !this.showChangePassword;
  }
  private newPasswordCheck() {
    this.showChangePasswordCheck = !this.showChangePasswordCheck;
  }

  // 패스워드 유효 검사
  private validation(password: string): boolean | null {
    const pwdRegExp = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[$@!%*#?&])[a-zA-Z\d$@!%*#?&]{8,}$/;
    const isOkPwdReg = pwdRegExp.test(password);
    const isNull = password.length === 0;
    if (isNull) return null;
    return isOkPwdReg;
  }
  // 변경될 비밀번호 확인
  validPassword() {
    if (this.currentPwd.length < 8) {
      this.$common.makeToast(ToastMessage.CHECK_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.refPassword.focus());
      return false;
    }
    if (this.changePwd !== this.changePwdCheck) {
      this.$common.makeToast(ToastMessage.NOT_MATCH_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      this.changePwd = '';
      this.changePwdCheck = '';
      this.$nextTick(() => this.refNewPassword.focus());
      return false;
    }

    if (this.changePwd.length < 8 && this.changePwdCheck.length < 8) {
      this.$common.makeToast(ToastMessage.SHORT_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      this.$nextTick(() => this.refNewPassword.focus());
      return false;
    }

    if (this.changePwd.length > 20 && this.changePwdCheck.length > 20) {
      this.$common.makeToast(ToastMessage.LONG_PASSWORD, ToastVariant.DANGER, this.$bvToast);
      return false;
    }
    return true;
  }

  get validationNewsPassword(): boolean | null {
    return this.validation(this.changePwd);
  }
  get validationPassword(): boolean | null {
    return this.validation(this.currentPwd);
  }
  get validationConfPassword(): boolean | null {
    const isNull = this.changePwdCheck.length === 0;
    if (isNull) return null;
    return this.changePwd === this.changePwdCheck;
  }

  get validationPwdMessage(): string | void {
    const isEqualPassword = true;
    if (!isEqualPassword) return '비밀번호가 같지 않습니다.';
    else return '비밀번호의 형식을 맞춰주십시오';
  }
  get validationPwdConfMessage(): string | void {
    return '비밀번호가 같지 않습니다.';
  }
}
