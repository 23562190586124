import { render, staticRenderFns } from "./MakeTemplateModal.vue?vue&type=template&id=55e2944f&scoped=true&"
import script from "./MakeTemplateModal.vue?vue&type=script&lang=ts&"
export * from "./MakeTemplateModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55e2944f",
  null
  
)

export default component.exports