












import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { QUOTA } from '@/types/quota';
import SETTING_OPTION = QUOTA.SETTING_OPTION;
import EventBus from '@/utils/EventBus';
import CreateNewProjectResetButtonModal from '@/components/main/create/button/CreateNewProjectResetButtonModal.vue';

@Component({
  components: { CreateNewProjectResetButtonModal },
})
export default class CreateNewProjectResetButton extends Vue {
  @Prop() private resetProp: QUOTA.SETTING_OPTION;

  resetModalState = false;

  click() {
    if (this.resetProp === SETTING_OPTION.ALL) this.$bvModal.show('create-project-reset-modal');
    else this.quotaReset();
  }

  get resetText() {
    return this.resetProp === SETTING_OPTION.ALL ? '전체' : '';
  }

  quotaReset() {
    EventBus.$emit('quota-reset', this.resetProp);
  }
}
