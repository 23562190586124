











import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class ScrollTopButton extends Vue {
  @Prop({required: true}) targetId: string;

  toTop() {
    setTimeout(() => {
      const ele = document.getElementById(this.targetId);
      if (ele) {
        ele.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);
  }
}
