






























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import type { IQuestionValue } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';
import { IAnswerValue } from '@/interface/survey/answer';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class Radio extends Vue {
  @Prop() private data: IQuestionValue;
  @Prop() private questionTypeName?: string;

  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };
  answers: IAnswerValue[] = [];

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      ANSWERS = [],
      RANDOM,
      TEXT_DENY,
      NAME,
      LEFT_CATEGORY = false,
      LEFT_CATEGORY_WIDTHS = '',
      LEFT_CATEGORY_ALIGNS = '',
      NUM_IN_ROW = '1',
    } = this.data;
    const answerBox = this.$refs['s-answer'];
    let showCount = 0;
    const rndAnswer = this.$common.rnd(ANSWERS, RANDOM, 'K');
    this.answers = rndAnswer;
    for (let a = 0; a < rndAnswer.length; a++) {
      const { K, N, V } = rndAnswer[a];
      showCount++;

      const unitBox: HTMLElement = this.$question.categoryMakerBox({
        wrapper: answerBox,
        wrapperAddClass: 'no-border',
        wrapperRemoveClass: 'pure-g',
        boxAddClass: 'pure-g',
        qname: NAME,
        leftCategory: LEFT_CATEGORY,
        leftCategoryWidths: LEFT_CATEGORY_WIDTHS,
        leftCategoryAligns: LEFT_CATEGORY_ALIGNS,
        unit: rndAnswer[a],
        units: rndAnswer,
      });

      const gridContent = document.createElement('div');
      gridContent.classList.add('answer-unit-box');
      unitBox.appendChild(gridContent);
      const numInRow = NUM_IN_ROW;
      const classArray = this.$common.numInRowToGrid(numInRow).split(' ');

      if (numInRow === '99') {
        answerBox.classList.remove('pure-g');
        gridContent.style.display = 'inline-block';
      } else {
        gridContent.classList.add(...classArray);
      }

      const div = document.createElement('div');
      div.classList.add('s-radio');
      gridContent.appendChild(div);

      const label = document.createElement('label');
      label.setAttribute('for', `${NAME}_${K}`);
      label.classList.add('pure-radio');
      div.appendChild(label);

      const input = document.createElement('input');
      input.setAttribute('type', `radio`);
      input.setAttribute('id', `${NAME}_${K}`);
      input.setAttribute('name', NAME);
      input.setAttribute('qname', NAME);
      input.setAttribute('akey', K);
      input.setAttribute('data-column', '1');
      input.setAttribute('data-next', N);
      input.value = K;

      label.appendChild(input);
      let html = V;
      //기타 박스 처리
      const reg = /\[(TEXT|NUMBER)_[\d]+\]/;
      let ord = 1;
      while (html.match(reg)) {
        const matchText = html.match(reg)![0];
        const typeNum = matchText.substr(1, matchText.length - 2).split('_');
        const type = typeNum[0];
        const num = typeNum[1];
        html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
        ord++;
        //2021-06-30 기타가 있으면 무조건 한 줄로 표시
        const etcClass = this.$common.numInRowToGrid('1').split(' ');
        gridContent.classList.remove(...classArray);
        gridContent.classList.add(...etcClass);
      }

      const span = document.createElement('span');
      span.innerHTML = html;
      span.classList.add('radio-value');
      label.appendChild(span);

      //기타 값 생성
      span.querySelectorAll('span[type]').forEach((item) => {
        const type = item.getAttribute('type');
        const num = item.getAttribute('num');
        const ord = item.getAttribute('ord');
        const dataColumn = K + '_ETC_' + ord;
        const textInput = document.createElement('input');
        textInput.setAttribute('type', 'text');
        textInput.classList.add('input-etc');
        textInput.style['width'] = `${this.data[type + '_' + num]?.WIDTH}px`;
        textInput.style['text-align'] = `${this.data[type + '_' + num]?.ALIGN}`;
        textInput.setAttribute('data-column', dataColumn);
        textInput.setAttribute('disabled', 'disabled');
        textInput.setAttribute('qname', NAME);
        textInput.setAttribute('akey', K);
        textInput.setAttribute('parent-name', NAME);
        textInput.setAttribute('type-name', type + '_' + num);
        textInput.setAttribute('data-object', JSON.stringify(this.data[type + '_' + num]));
        //최대 글자수 제한
        if ((this.data[type + '_' + num]?.MAX_LENGTH || '') != '') {
          textInput.setAttribute('maxlength', this.data[type + '_' + num]?.MAX_LENGTH);
        }
        item.appendChild(textInput);
      });
    }
  }

  async initEvent(): Promise<void> {
    //radio 값 변경시 etc 처리
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const { NAME } = this.data;
    const radios: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="radio"]') || [];
    for (const radio of radios) {
      radio.addEventListener('click', this.radioChangeEtcEvent);
    }
    const etcList: NodeListOf<HTMLElement> = answerBox.querySelectorAll('span[type]') || [];
    for (const etc of etcList) {
      etc.addEventListener('click', this.etcClickRadioEvent);
    }
  }

  etcClickRadioEvent(evt) {
    const { target } = evt;
    const radio = target.closest('label').querySelector('input[type="radio"]');
    radio.setAttribute('checked', 'checked');
    radio.click();
  }

  radioChangeEtcEvent(evt) {
    const { target } = evt;
    const akey = target.value;
    const name = target.getAttribute('name');
    const wrapper = target.closest('div[question-name]');
    const etc = wrapper.querySelectorAll('input.input-etc[parent-name="' + name + '"]');
    etc.forEach((item, idx) => {
      if (item.getAttribute('akey') != akey) {
        item.value = '';
        item.setAttribute('disabled', 'disabled');
      } else {
        if (item.getAttribute('disabled') === 'disabled') {
          item.removeAttribute('disabled');
          item.focus();
        } else {
          item.removeAttribute('disabled');
        }
      }
    });

    wrapper
      .querySelector('input[type=radio]:not(:checked)')
      .closest('.answer-unit-box')
      .classList.remove('answer-unit-selected');
    wrapper
      .querySelector('input[type=radio]:checked')
      .closest('.answer-unit-box')
      .classList.remove('answer-unit-selected');
  }
}
