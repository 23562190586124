










import {Component, Vue} from "vue-property-decorator";

@Component
export default class ResultIcon extends Vue {}

