








import { Component, Vue } from 'vue-property-decorator';
import ProfileInfo from '@/components/users/profile/ProfileInfo.vue';
import PaymentInfo from '@/components/users/profile/PaymentInfo.vue';
import UserDeleteBtn from '@/components/users/profile/Withdrawal.vue';
import ProfileLayout from '@/views/users/ProfileLayout.vue';

@Component({ components: { ProfileLayout, UserDeleteBtn, PaymentInfo, ProfileInfo } })
export default class Profile extends Vue {}
