














import { Component, Prop, Vue } from 'vue-property-decorator';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class HelpDeleteModal extends Vue {
  @Prop() listId?: string;
  @Prop() category?: string;

  async remove(): Promise<void> {
    try {
      const { data } = await this.axios.delete(`/board/help-delete/${this.listId}`);
      const { result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.DELETE, ToastVariant.DANGER, this.$bvToast);
        await this.$router.push(`/board/admin-help-list`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  cancel() {
    this.$bvModal.hide('board-remove-modal');
  }
}
