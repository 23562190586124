























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { BOARD, IHelp } from '@/types/board';
import InfiniteLoading from 'vue-infinite-loading';
import HelpLists from "@/components/board/HelpLists.vue";

@Component({
  components: {
    HelpLists,
    InfiniteLoading,
  },
})
export default class HelpPopup extends Vue {
  @Prop({ default: 'help-modal-99' }) modalId: string;
  /*@Prop() modalName: string;*/

  loading: boolean = true;
  search: string = '';
  searchFlag: boolean = false;
  helpTopLimit: number = 5;
  limit: number = 5;
  page: number = 1;
  topHelpList: IHelp[] = [];
  searchHelpList: IHelp[] = [];
  helpId: string = '';
  infiniteId = +new Date();
  $refs: Vue['$refs'] & {
    formFile: HTMLFormElement;
    helpView: HelpLists;
    InfiniteLoading: InfiniteLoading;
  };

  async openHelp() {
    this.helpId = '';
    this.topHelpList = [];
    this.searchFlag = false;
    this.page = 1;
    this.search = '';
    await this.selectTopHelp();
    this.$bvModal.show(this.modalId);
  }

  async selectTopHelp() {
    try {
      const sendData = {
        limit: this.helpTopLimit,
      };
      const { data } = await this.axios.get(`/board/top-help`, { params: sendData });
      this.loading = false;
      const { result, list } = data;
      if (result) {
        this.topHelpList = list;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async selectHelpList($state: any): Promise<void> {
    try {
      this.loading = true;
      const sendData = {
        limit: this.limit,
        page: this.page,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, { params: sendData });
      const { result, count, list } = data;

      if (list.length > 0 && result) {
        await setTimeout(() => {
          this.searchHelpList.push(...list);
          this.page++;
          $state.loaded();
        }, 1000);
      } else {
        this.loading = false;
        $state.complete();
      }
    } catch (e) {
      console.log(e);
    }
  }

  redirectQuestion() {
    const target = '/board/question-list';
    const { path } = this.$route;
    if (path.indexOf(target) > -1) this.$bvModal.hide(this.modalId);
    else this.$router.push('/board/question-list');
  }

  async showHelp(type: string, index: number) {
    if (type == 'search') {
      this.helpId = this.searchHelpList[index]['_id'];
    } else {
      this.helpId = this.topHelpList[index]['_id'];
    }
    await this.$bvModal.show(`help-view-${this.modalId}`);
    await this.$refs.helpView.load();
  }

  categoryVariant(category: BOARD.HELP_CATEGORY): string {
    if (category === BOARD.HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === BOARD.HELP_CATEGORY.USE) return 'success';
    else return 'primary';
  }

  boardQuestionDetail() {
    this.$router.push(`/board/help-view/${this.helpId}`);
  }

  searchHelp() {
    this.searchFlag = true;
    this.page = 1;
    this.searchHelpList = [];
    this.infiniteId += 1;
  }
}
