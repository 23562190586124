



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class OptionColor extends Vue {
  @Prop({ required: true }) color: string;
  @Prop({ default: '색상 : ' }) label: string;
  @Prop({ required: true }) readonly manualType: number;

  colorValue: string = '';

  @Watch('color')
  watchColor() {
    this.colorValue = this.color;
  }

  mounted() {
    this.watchColor();
  }

  setColorData() {
    this.$emit('set-data', this.colorValue);
  }

  private showManual() {
    const none = require('@/assets/images/options/none-background-color.png');
    const use = require('@/assets/images/options/use-background-color.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: `<div>
                    <h1 class="option-description--text"><b>입력한 내용의 배경 색상을 설정합니다.</b></h1>
                    <hr />
                    <b class="option-description--text-blue" class="mt-2">[ 미적용 ]</b>
                    <img alt="미제시" style='width: 100%;' src="${none}" />
                    <hr />
                    <b class="option-description--text-blue" class="mt-2">[ 배경 색상 사용 ]</b>
                    <img alt="미제시" style='width: 100%;' src="${use}" />
                </div>`,
    };
  }
  private showManualBorder() {
    const none = require('@/assets/images/options/none-background-color.png');
    const use = require('@/assets/images/options/use-background-color.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'top',
      content: `<div>
                    <h1><b style="color: #004fab; font-weight: 500;">안내문 테두리의 색을 지정합니다.</b></h1>
                </div>`,
    };
  }
}
