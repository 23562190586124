


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionValue, IQuestionText } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class MultiText extends Vue {
  @Prop() private data: IQuestionText;
  @Prop() private questionTypeName?: string;

  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      WIDTH = '200',
      MIN_COUNT = '1',
      MAX_COUNT = '1',
      MIN_LENGTH = '1',
      MAX_LENGTH = '9999',
      RESTRICT = [],
      NAME,
      HEAD = '',
      TAIL = '',
      PASS = '',
    } = this.data;
    const answerBox = this.$refs['s-answer'];
    const div = document.createElement('div');
    div.classList.add('s-multi-text');
    answerBox.appendChild(div);

    answerBox.setAttribute('MIN_COUNT', String(MIN_COUNT));
    answerBox.setAttribute('MAX_COUNT', String(MAX_COUNT));
    answerBox.setAttribute('MIN_LENGTH', String(MIN_LENGTH));
    answerBox.setAttribute('MAX_LENGTH', String(MAX_LENGTH));
    answerBox.setAttribute('RESTRICT', JSON.stringify(RESTRICT));

    for (let i = 1; i <= Number(MAX_COUNT); i++) {
      const line = document.createElement('div');
      div.appendChild(line);
      var dataColumn = String(i);

      if (HEAD != '') {
        const span = document.createElement('span');
        span.innerHTML = HEAD;
        line.appendChild(span);
      }
      const input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.classList.add('multi-text');
      line.appendChild(input);

      if (TAIL != '') {
        const span = document.createElement('span');
        span.innerHTML = TAIL;
        line.appendChild(span);
      }

      input.style.width = `${WIDTH}px`;
      input.setAttribute('ord', String(i));
      input.setAttribute('qname', NAME);
      input.setAttribute('data-column', dataColumn);
      input.setAttribute('maxlength', String(MAX_LENGTH));
    }

    //무응답 통과문구가 있는 경우
    if (PASS !== '') {
      const div = document.createElement('dv');
      div.classList.add('s-pass');
      answerBox.appendChild(div);

      const label = document.createElement('label');
      label.classList.add('pure-checkbox');
      label.setAttribute('for', `${NAME}_PASS`);
      div.appendChild(label);
      const input = document.createElement('input');
      input.setAttribute('type', 'checkbox');
      label.appendChild(input);
      input.setAttribute('id', `${NAME}_PASS`);
      input.setAttribute('qname', NAME);
      input.setAttribute('name', `${NAME}_PASS`);
      input.setAttribute('data-column', PASS);
      input.setAttribute('data-pass', 'true');

      const span = document.createElement('span');
      label.style.marginTop = '7px';
      span.innerHTML = PASS;
      label.appendChild(span);
    }
  }

  async initEvent(): Promise<void> {
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const pass = answerBox.querySelector('input[data-pass="true"]');
    if (pass) pass.addEventListener('click', this.passEvent);
  }

  passEvent(e) {
    const { target } = e;
    const { checked } = target;
    const answerBox = this.$refs['s-answer'];
    const { NAME } = this.data;
    const multiText: NodeListOf<HTMLInputElement> = answerBox.querySelectorAll(`input.multi-text[qname="${NAME}"]`);
    if (checked) {
      multiText.forEach((item) => {
        item.setAttribute('disabled', 'disabled');
        item.value = '';
      });
    } else {
      multiText.forEach((item) => {
        item.removeAttribute('disabled');
      });
    }
  }
}
