















import { Component, Vue } from 'vue-property-decorator';
import ProjectTopMenu from '@/components/project/ProjectTopMenu.vue';
import createNewProject from '@/components/main/create/CreateNewProject.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import { QUESTION } from '@/types/question';
import LINK_STATUS = QUESTION.LINK_STATUS;

@Component({
  components: {
    ProjectTopMenu,
    createNewProject,
  },
})
export default class Sampling extends Vue {
  private projectId: number = -1;
  private isLoading: boolean = true;
  settingQuota = null;
  surveyType: number = 0;
  linkStatus: number = -1;

  $refs: Vue['$refs'] & {
    sampling: createNewProject;
  };

  async created(): Promise<void> {
    this.projectId = Number(this.$route.params.id);
  }

  async mounted(): Promise<void> {
    await this.load();
  }

  async load() {
    try {
      this.isLoading = true;
      const { data } = await this.axios.get(`/project/quota/info/${this.projectId}`);
      const { info } = data;
      const { CONFIG } = info;
      const { SIMPLE_SURVEY_QUOTA, SIMPLE_SURVEY_TYPE, LINK_STATUS } = CONFIG;
      this.settingQuota = SIMPLE_SURVEY_QUOTA;
      if (SIMPLE_SURVEY_TYPE) this.surveyType = SIMPLE_SURVEY_TYPE;
      this.linkStatus = LINK_STATUS;

      await this.$nextTick(async () => {
        if (this.settingQuota)
          await this.$refs.sampling.setSettingQuota(this.settingQuota, SIMPLE_SURVEY_TYPE.toString());
      });

      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async setSamplingUpdate(settingQuota) {
    this.isLoading = true;
    try {
      const { data } = await this.axios({
        url: `/project/sampling/${this.projectId}`,
        method: 'PUT',
        data: settingQuota,
      });

      const { result } = data;
      this.isLoading = false;
      if (result) {
        this.$common.makeToast(ToastMessage.SAMPLING_UPDATE, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.push({ path: `/project/make/${this.projectId}` });
      }
    } catch (e) {
      console.error(e);
    }
  }

  get paymentComplete(): boolean {
    return (
      this.linkStatus <= LINK_STATUS.REFUND &&
      this.linkStatus !== LINK_STATUS.REAL &&
      this.linkStatus !== LINK_STATUS.PAID
    );
  }

  get isSampleProject(): boolean {
    return this.linkStatus === LINK_STATUS.SAMPLE;
  }
}
