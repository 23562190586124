

























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionGradeClick } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';
import { QUESTION } from '@/types/question';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class GradeClick extends Vue {
  @Prop() private data: IQuestionGradeClick;
  @Prop() private questionTypeName?: string;

  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
    're-select-div': HTMLElement;
  };
  randomResult: string = '';

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      RANDOM,
      TEXT_DENY,
      NAME,
      V = [],
      SELECTED_SHOW = true,
      MAX_GRADE = '3',
      MIN_GRADE = '1',
      NUM_IN_ROW = '1',
    } = this.data;
    this.randomResult = V.map(function (a) {
      return a.K;
    }).join(',');
    const answerBox = this.$refs['s-answer'];

    //다시선택 버튼
    const reSelectButton = document.createElement('button');
    reSelectButton.classList.add('pure-button', 'mid', 're-select');
    reSelectButton.innerHTML = '다시선택';
    reSelectButton.setAttribute('qname', NAME);
    this.$refs['re-select-div'].appendChild(reSelectButton);

    let gridBox = document.createElement('div');
    gridBox.classList.add('pure-g');
    answerBox.appendChild(gridBox);
    let showCount = 0;
    const rndAnswer = this.$common.rnd(V, RANDOM, 'K');
    let prevCategory = '';

    for (let a = 0; a < rndAnswer.length; a++) {
      const { K, C1, V, SINGLE = false, LAST, MAX } = rndAnswer[a];
      //console.log(rndAnswer[a]);
      showCount++;
      const contentClass = this.$common.numInRowToGrid(NUM_IN_ROW).split(' ');

      if (prevCategory != (C1 || '')) {
        //이전 보기와 카테고리가 같지 않으면 카테고리 줄을 만들어 준다
        const categoryHeader = document.createElement('div');
        categoryHeader.classList.add('category-header');
        categoryHeader.innerHTML = C1;
        answerBox.appendChild(categoryHeader);
        gridBox = document.createElement('div');
        gridBox.classList.add('category-body', 'pure-g');
        answerBox.appendChild(gridBox);
      }
      prevCategory = C1 || '';

      const gridContent = document.createElement('div');
      gridContent.classList.add('answer-unit-box', 'pointer');
      gridBox.appendChild(gridContent);

      if (NUM_IN_ROW === '99') {
        answerBox.classList.remove('pure-g');
        gridContent.style.display = 'inline-block';
      } else {
        gridContent.classList.add(...contentClass);
      }

      const div = document.createElement('div');
      div.classList.add('s-gradeclick');
      gridContent.appendChild(div);

      const gradeBox = document.createElement('div');
      gradeBox.classList.add('grade-box');
      div.appendChild(gradeBox);
      div.setAttribute('qname', NAME);
      div.setAttribute('aKey', K);
      div.setAttribute('data-single', SINGLE || '');
      div.setAttribute('data-last', LAST || '');
      div.setAttribute('data-max', MAX || '');

      //2021-06-30 단독선택 무조건 한 줄로 표시
      /*
            if(SINGLE === true){
                const etcClass = this.$common.numInRowToGrid('1').split(' ');
                gradeBox.classList.remove(...contentClass);
                gradeBox.classList.add(...etcClass)
            }
            */

      const span = document.createElement('span');
      span.innerHTML = V;
      span.classList.add('value', 'text-hover');
      div.appendChild(span);

      //기타 박스 처리
      let html = V;
      const etcResult = this.$question.makeEtc({
        html,
        gridContent,
        wrapper: span,
        numInRow: NUM_IN_ROW,
        qo: this.data,
        aKey: K,
      });
    }

    let maxGrade = Number(MAX_GRADE);
    let minGrade = Number(MIN_GRADE);
    if (maxGrade > showCount) maxGrade = showCount;
    if (minGrade > showCount) minGrade = showCount;
    for (let i = 1; i <= maxGrade; i++) {
      const showRow = document.createElement('div');
      showRow.classList.add('selected-grade');
      if (SELECTED_SHOW !== false) {
        const selectedShowBox = document.querySelector('div.selected-show[qname="' + NAME + '"]');
        if (selectedShowBox) {
          selectedShowBox.appendChild(showRow);
          const gradeHead = document.createElement('span');
          gradeHead.classList.add('grade-head');
          gradeHead.innerHTML = `${i}순위 : `;
          showRow.appendChild(gradeHead);
        }
      }
      const gradeBody = document.createElement('span');
      gradeBody.classList.add('grade-body');
      gradeBody.setAttribute('grade', String(i));
      showRow.appendChild(gradeBody);
    }
  }

  async initEvent(): Promise<void> {
    //radio 값 변경시 etc 처리
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const moduleName = QUESTION.QUESTION_TYPES.GRADE_CLICK;
    const grades: NodeListOf<HTMLElement> =
      answerBox.querySelectorAll('div.s-gradeclick,div[question-type="' + moduleName + '"] div.s-gradeclick') || [];
    for (const grade of grades) {
      grade.addEventListener('click', this.gradeClickEvent);
    }
    const reSelect = document.querySelector('div[question-type="' + moduleName + '"] button.re-select');
    if (reSelect) {
      reSelect.addEventListener('click', this.reSelect);
    }

    /*
        const etcList = answerBox.querySelectorAll('span[type]');
        for (const etc of etcList) {
            etc.addEventListener('click', this.etcClickRadioEvent);
        }

         */
  }

  gradeClickEvent(evt) {
    const { target } = evt;
    const tag = target.tagName;
    const type = target.getAttribute('type');
    const disabled = target.getAttribute('disabled');
    const answerBox = this.$refs['s-answer'];
    //기타 칸을 클릭한 것이고, 입력 가능한 상태라면 순위클릭 처리를 하면 안된다
    if (tag.toUpperCase() === 'INPUT' && type === 'text' && disabled !== 'disabled') return;

    //무응답에 의해 disabled 상태면 처리안함
    const gradeDiv = target.closest('div.s-gradeclick');
    if (String(gradeDiv.className).indexOf('noa-disabled') > -1) {
      return;
    }
    const qname = gradeDiv.getAttribute('qname');
    const wrapper = document.querySelector('div[question-name="' + qname + '"]');
    const maxGrade = Number(wrapper?.getAttribute('max-grade'));
    const selected = wrapper?.querySelectorAll('div.s-gradeclick[qname="' + qname + '"].selected');
    //다음 순위
    const nextGrade = Number((selected?.length || 0) + 1);

    if (String(gradeDiv.className).indexOf('selected') > -1) {
      //이미 순위를 갖고 있는 경우
      const gradeBox = gradeDiv.querySelector('div.grade-box');
      const currentGrade = Number(gradeBox.getAttribute('data-current-grade'));
      //해당 순위 해제
      gradeDiv.classList.remove('selected');
      gradeBox.innerHTML = '';
      gradeBox.removeAttribute('data-current-grade');
      const etc = gradeDiv.querySelector('input[type="text"]');
      if (etc) {
        etc.value = '';
        etc.setAttribute('disabled', 'disabled');
      }

      //해당 순위보다 뒷 순위를 당겨야 한다
      (selected || []).forEach((item) => {
        const gradeBox = item.querySelector('div.grade-box');
        const grade = Number(gradeBox.getAttribute('data-current-grade'));
        const isSingle = gradeBox.getAttribute('data-single') == 'true';
        const last = Number(gradeBox.getAttribute('data-last'));
        const prevGrade = grade - 1;
        if (grade > currentGrade) {
          gradeBox.innerHTML = prevGrade;
          gradeBox.setAttribute('data-current-grade', String(prevGrade));
        }
        //순위를 당겼는데, 단독선택으로 선택할 수 있는 최소 순위보다 작아졌으면 해제해야 한다
        if (isSingle === true && prevGrade < last) {
          item.click();
        }
      });
    } else {
      // 새로 순위를 넣어줘야하는 경우
      if (nextGrade > maxGrade) {
        //최대 순위를 넘으면 중지
        return;
      }
      //더이상 없음 순위인 경우, 최소 순위 제한이 있다
      if (gradeDiv.getAttribute('data-single') == 'true') {
        let last = Number(gradeDiv.getAttribute('data-last'));
        if (last < 1) last = 1;
        if (nextGrade < last) {
          return;
        }
      }

      //더이상 없음 순위인 경우, 최대 순위 제한이 있다
      let dataMax = gradeDiv.getAttribute('data-max') || '';
      if (dataMax != '' && !isNaN(dataMax)) {
        dataMax = Number(dataMax);
        if (nextGrade > dataMax) {
          return;
        }
      }

      //더이상 없음이 선택되어 있으면 선택할 수 없다
      let noMoreExists = false;
      (selected || []).forEach((item) => {
        if (item.getAttribute('data-single') == 'true') {
          noMoreExists = true;
          return false;
        }
      });

      //더이상 없음이 선택되어 있음
      if (noMoreExists) {
        return;
      }
      //순위 넣어줌
      gradeDiv.classList.add('selected');
      const gradeBox = gradeDiv.querySelector('div.grade-box');
      gradeBox.innerHTML = nextGrade;
      gradeBox.setAttribute('data-current-grade', String(nextGrade));
      const etc = gradeDiv.querySelectorAll('input[type="text"]');
      etc.forEach((item) => item.removeAttribute('disabled'));
    }
    this.showSelected(wrapper);
  }

  showSelected(wrapper) {
    if (wrapper) {
      const qname = this.data.NAME;
      const selectedShow = document.querySelector('div.selected-show[qname="' + qname + '"]');
      (selectedShow?.querySelectorAll('span[grade]') || []).forEach((item) => (item.innerHTML = ''));

      const gradeClickSelected = wrapper.querySelectorAll('div.s-gradeclick.selected[qname="' + qname + '"]');
      gradeClickSelected.forEach((item) => {
        const grade = item.querySelector('div.grade-box').getAttribute('data-current-grade');
        let html = item.querySelector('span.value').outerHTML;
        const etcList = item.querySelectorAll('input[type="text"]');
        if (etcList.length > 0) {
          html = '';
          const etcValues: string[] = [];
          etcList.forEach((etc) => etcValues.push(etc.value));
          html = etcValues.join(' ');
        }
        //console.log(grade, html);
        const newGrade = selectedShow?.querySelector('span[grade="' + grade + '"]') || document.createElement('span');
        newGrade.innerHTML = html;
      });
    }
  }

  reSelect(evt) {
    const { target } = evt;
    const tag = target.tagName;
    const qname = this.data.NAME;
    const wrapper = document.querySelector('div[question-name="' + qname + '"]');
    const selected = wrapper?.querySelectorAll('div.s-gradeclick[qname="' + qname + '"].selected');
    (selected || []).forEach((item) => {
      const gradeBox = item.querySelector('div.grade-box');
      const etc = item.querySelector('input.input-etc');
      gradeBox.innerHTML = '';
      gradeBox.removeAttribute('data-current-grade');
      item.classList.remove('selected');
      if (etc) {
        etc.value = '';
        etc.setAttribute('disabled', 'disabled');
      }
    });
    this.showSelected(wrapper);
  }

  beforeDestroy() {}
}
