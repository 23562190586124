






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionCheckSets } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';
import { IAnswerValue } from '@/interface/survey/answer';
import { QUESTION } from '@/types/question';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class CheckSets extends Vue {
  @Prop() private data: IQuestionCheckSets;
  @Prop() private questionTypeName?: string;

  answers: IAnswerValue[] = [];
  hAnswer: IAnswerValue[] = [];
  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    let table, thead, theadd, tr, vWidth, th, showCount, td;
    const answerBox = this.$refs['s-answer'];
    const {
      NAME,
      V = [],
      H = [],
      BY_CARD = false,
      KEY_SHOW = false,
      FIXED_THEAD = false,
      MOBILE_UI = false,
      V_WIDTH = '40',
      COL_WIDTHS = '',
      RANDOM = '',
      RANDOM_H = '',
      V_HEAD = '',
    } = this.data;
    //랜덤
    this.answers = this.$common.rnd(V, RANDOM, 'K');
    this.hAnswer = this.$common.rnd(H, RANDOM_H, 'K');
    const card = BY_CARD;
    let cardDiv;
    let BY_LINE = this.data.BY_LINE || false;
    //카드로 하나씩 제시인가?
    if (card) {
      cardDiv = document.createElement('div');
      cardDiv.classList.add('card-div');
      cardDiv.setAttribute('qname', NAME);
      cardDiv.style.width = '100%';
      cardDiv.style.margin = '24px 0';
      cardDiv.style.border = '1px solid #999';
      cardDiv.style.boxShadow = '3px 3px 8px';
      cardDiv.style.borderRadius = '.5em';
      cardDiv.style.fontSize = '150%';
      cardDiv.style.textAlign = 'center';
      cardDiv.style.padding = '.5em 0 .8em';

      const span = document.createElement('span');
      span.innerText = '&nbsp';
      cardDiv.appendChild(span);
      answerBox.appendChild(cardDiv);

      if (MOBILE_UI === true) cardDiv.style.fontSize = '150%';
      //카드로 하나씩이면 한줄씩은 무조건 적용해야 한다
      BY_LINE = true;
    }

    table = document.createElement('table');
    table.classList.add('common', 'pure-table', 'pure-table-bordered', 'w-100', 'pure-table-striped');
    answerBox.appendChild(table);
    if (FIXED_THEAD === true) table.classList.add('fixed-top');
    thead = document.createElement('thead');
    table.appendChild(thead);
    vWidth = V_WIDTH;

    let catTr;
    if (H.length > 0 && (H[0].C1 || '') !== '') {
      catTr = document.createElement('tr');
      thead.appendChild(catTr);
      th = document.createElement('th');
      th.classList.add('first-th');
      th.style.width = `${vWidth}$`;
      th.setAttribute('rowspan', '2');
      catTr.appendChild(th);
      if (BY_CARD === true) th.style.display = 'none';
      tr = document.createElement('tr');
      thead.appendChild(tr);
    } else {
      tr = document.createElement('tr');
      thead.appendChild(tr);
      th = document.createElement('th');
      th.classList.add('first-th');
      th.style.width = `${vWidth}$`;
      if (BY_CARD === true) th.style.display = 'none';
      tr.appendChild(th);
    }

    //설명 머릿말 있으면 표시
    if (V_HEAD !== '') {
      th.innerHTML = V_HEAD;
    }

    //보기 분류가 있는 경우
    if (V.length > 0 && (V[0].C1 || '') != '') th.setAttribute('colspan', '2');

    //값 머릿말 추가
    let hCount = 0;
    for (let h = 0; h < this.hAnswer.length; h++) {
      const H = this.hAnswer[h];
      hCount++;

      //카테고리 추가
      if ((H.C1 || '') != '') {
        const lastTh = catTr.querySelector('th:last-child');
        const lastThText = lastTh.innerHTML;
        if (lastThText != '' && H.C1 === lastThText) {
          //마지막 카테고리가 공백이 아니고 이번 카테고리와 같으면 병합
          const colspan = Number(lastTh.getAttribute('colspan') || '1');
          lastTh.setAttribute('colspan', String(colspan + 1));
        } else {
          th = document.createElement('th');
          th.innerHTML = H.C1;
          th.setAttribute('column-index', String(hCount));
          th.style.padding = '8px 2px';
          catTr.appendChild(th);
          //카테고리와 응답값 머릿말이 같으면 병합한다
          if (H.C1 === H.V) th.setAttribute('rowspan', '2');
        }
      }

      //카테고리와 응답값 머릿말이 같으면 병합했을테니 다를 경우에만 응답값 머릿말을 추가한다
      if ((H.C1 || '') != H.V) {
        //응답값 머릿말 추가
        th = document.createElement('th');
        tr.appendChild(th);
        const hKey = H.K;
        th.setAttribute('hKey', hKey);
        th.setAttribute('column-index', String(hCount));
        th.style.padding = '8px 2px';

        let html = H.V;
        const reg = /\[TEXT_[\d]+\]/;
        let ord = 1;
        while (html.match(reg)) {
          const matchText = html.match(reg)![0];
          const typeNum = matchText.substr(1, matchText.length - 2).split('_');
          const type = typeNum[0];
          const num = typeNum[1];
          html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
          ord++;
          th.classList.add('has-etc');
        }
        th.innerHTML = html;

        th.querySelectorAll('span[type][num]').forEach((item) => {
          const type = item.getAttribute('type');
          const num = item.getAttribute('num');
          const ord = item.getAttribute('ord');
          const dataColumn = hKey + '_HETC_' + ord;
          const textInput = document.createElement('input');
          textInput.setAttribute('type', 'text');
          textInput.classList.add('input-etc');
          textInput.style['width'] = `${this.data[type + '_' + num].WIDTH}px`;
          textInput.style['text-align'] = `${this.data[type + '_' + num].ALIGN}`;
          textInput.setAttribute('data-column', dataColumn);
          textInput.setAttribute('hkey', hKey);
          item.appendChild(textInput);
        });
      }
    }

    const hWidth = (100 - Number(vWidth)) / hCount;
    tr.querySelectorAll('th[hKey]').forEach((item) => (item.style.width = `${hWidth}%`));

    //보기 추가
    const tbody = document.createElement('tbody');
    table.appendChild(tbody);
    showCount = 0;
    let answerCategoryExists = false;

    for (let v = 0; v < this.answers.length; v++) {
      const answer = this.answers[v];
      const vKey = answer.K;
      const category = answer.C1 || '';
      showCount++;

      tr = document.createElement('tr');
      tbody.appendChild(tr);
      tr.setAttribute('qname', NAME);
      tr.setAttribute('vKey', vKey);
      tr.setAttribute('category', category);

      //보기 분류가 있는 경우
      if (category != '') {
        //previousElementSibling 함수는 IE9 부터 됨
        const prevCategory = tr.previousElementSibling?.getAttribute('category');
        if (prevCategory == category && category != '[MERGE]') {
          //분류가 같으면 병합해야 한다
          let targetRow = tr.previousElementSibling;
          while (targetRow.querySelectorAll('td.row-head').length === 0) {
            targetRow = targetRow.previousElementSibling;
            if (targetRow.length == 0) break;
          }

          let rowspan = Number(targetRow.querySelector('td.row-head').getAttribute('rowspan') || '1');
          targetRow.querySelector('td.row-head').setAttribute('rowspan', String(++rowspan));
        } else {
          //분류가 같지 않으면 새로 만듦
          td = document.createElement('td');
          td.classList.add('row-head', 'text-center');
          td.innerHTML = category;
          tr.appendChild(td);
          if (MOBILE_UI === true) td.classList.add('mobile-ui-hide');
        }
      }

      //카테고리에 [MERGE]가 입력되면 V와 병합
      if (category === '[MERGE]') {
        td.setAttribute('colspan', '2');
      } else {
        td = document.createElement('td');
        tr.appendChild(td);
      }

      if (BY_CARD === true) {
        td.style.display = 'none';
        td.setAttribute('data-V', answer.V);
        tr.querySelectorAll('.row-head').forEach((item) => (item.style.display = 'none'));
        if (showCount == 1) {
          const cardSpan = cardDiv.querySelector('span');
          cardSpan.innerHTML = answer.V;
          cardSpan.removeAttribute('show-tooltip');
        }
        //툴팁은 생략
        /*
                (data.TOOLTIP || []).forEach(function (tt) {
                    if (tt.K == data.V[v].K) {
                        $(cardDiv).find('span').attr('show-tooltip', Replacer(tt.V));
                    }
                });
                */
      }

      //기타 박스 처리
      let html = answer.V;
      const reg = /\[TEXT_[\d]+\]/;
      let ord = 1;
      while (html.match(reg)) {
        const matchText = html.match(reg)![0];
        const typeNum = matchText.substr(1, matchText.length - 2).split('_');
        const type = typeNum[0];
        const num = typeNum[1];
        html = html.replace(matchText, '<span type="' + type + '" num="' + num + '" ord="' + ord + '"></span>');
        ord++;
      }
      td.innerHTML = html;

      //한줄씩 표시
      if (BY_LINE === true && showCount > 1) {
        tr.style.display = 'none';
        tr.classList.add('invisible');
      }

      //기타 값 생성
      td.querySelectorAll('span[type][num]').forEach((item) => {
        const type = item.getAttribute('type');
        const num = item.getAttribute('num');
        const ord = item.getAttribute('ord');
        const dataColumn = vKey + '_ETC_' + ord;
        const textInput = document.createElement('input');
        textInput.setAttribute('type', 'text');
        textInput.classList.add('input-etc');
        textInput.style['width'] = `${this.data[type + '_' + num].WIDTH}px`;
        textInput.style['text-align'] = `${this.data[type + '_' + num].ALIGN}`;

        textInput.setAttribute('data-column', dataColumn);
        //textInput.setAttribute('disabled', 'disabled');
        textInput.setAttribute('qname', NAME);
        textInput.setAttribute('vkey', vKey);
        textInput.setAttribute('parent-name', NAME);
        textInput.setAttribute('type-name', type + '_' + num);
        textInput.setAttribute('data-object', JSON.stringify(this.data[type + '_' + num]));
        item.appendChild(textInput);
      });

      for (let h = 0; h < this.hAnswer.length; h++) {
        const H = this.hAnswer[h];
        const hKey = H.K;

        const dataColumn = vKey + '_' + hKey;
        td = document.createElement('td');
        td.classList.add('answer-unit-box', 'text-center', 'td-radio');
        td.setAttribute('hkey', hKey);
        td.setAttribute('vkey', vKey);
        tr.appendChild(td);

        const label = document.createElement('label');
        label.setAttribute('for', `${NAME}_${vKey}_${hKey}`);
        label.classList.add('pure-checkbox');
        td.appendChild(label);

        const input = document.createElement('input');
        input.setAttribute('type', `checkbox`);
        input.setAttribute('id', `${NAME}_${vKey}_${hKey}`);
        input.setAttribute('name', `${NAME}_${vKey}`);
        input.setAttribute('qname', NAME);
        input.setAttribute('vkey', vKey);
        input.setAttribute('hkey', hKey);
        input.setAttribute('qtype', QUESTION.QUESTION_TYPES.CHECKSETS);
        input.setAttribute('data-column', dataColumn);
        input.setAttribute('data-single', String(H.SINGLE || ''));

        input.value = hKey;
        label.appendChild(input);

        //한줄씩 표시
        /*
                if (BY_LINE === true && showCount > 1) {
                    tr.style.display = 'none';
                    tr.classList.add('invisible');
                }
                 */
      }
    }
    this.visibleProcess(answerBox);
  }

  async initEvent(): Promise<void> {
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    //td 부분을 클릭해도 checkbox가 클릭되도록 함
    const tds: NodeListOf<HTMLElement> = answerBox.querySelectorAll('td.td-radio') || [];
    for (const td of tds) {
      td.addEventListener('click', this.checkboxClick);
    }

    const texts: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="text"][data-column]') || [];
    for (const text of texts) {
      text.addEventListener('keyup', this.etcKeyup);
    }

    //값 변경시
    const checkboxList: NodeListOf<HTMLElement> = answerBox.querySelectorAll('input[type="checkbox"]') || [];
    for (const checkbox of checkboxList) {
      checkbox.addEventListener('click', this.checkboxLogic);
    }
  }
  //값 변경시
  checkboxLogic(evt) {
    const { target } = evt;
    const { NAME } = this.data;
    const answerBox = this.$refs['s-answer'];
    const wrapper = target.closest('div[question-name]');
    const checked = target.checked;
    const single = target.getAttribute('data-single') || '';
    const selectedHkey = target.getAttribute('hKey') || '';
    const vkey = target.getAttribute('vKey') || '';
    const qname = target.getAttribute('qname') || '';
    if (checked) {
      if (single == 'true') {
        const checkboxList = answerBox.querySelectorAll(
          'input[type=checkbox][qname="' + qname + '"][vkey="' + vkey + '"]'
        ) as NodeListOf<HTMLInputElement>;
        checkboxList.forEach((item) => {
          const hkey = item.getAttribute('hKey');
          if (hkey == selectedHkey) return true;
          if (item.checked == true) {
            item.checked = false;
            //item.dispatchEvent( sampling Event('click'));
          }
        });
      } else {
        //단독이 아닌 보기를 고른 경우 단독을 체크해제
        const checkboxList = answerBox.querySelectorAll(
          'input[type=checkbox][qname="' + qname + '"][vkey="' + vkey + '"][data-single=true]'
        ) as NodeListOf<HTMLInputElement>;
        checkboxList.forEach((item) => {
          if (item.checked == true) {
            item.checked = false;
            //item.removeAttribute('checked');
            //item.dispatchEvent( sampling Event('click'));
          }
        });
      }
    }
    this.maxCheck(wrapper);
    //선택된 항목의 부모에 선택되었음을 표시
    wrapper.querySelectorAll('input[type=checkbox]:not(:checked)').forEach((item) => {
      item.closest('.answer-unit-box').classList.remove('answer-unit-selected');
    });

    wrapper.querySelectorAll('input[type=checkbox]:checked').forEach((item) => {
      item.closest('.answer-unit-box').classList.add('answer-unit-selected');
    });
  }

  checkboxClick(evt) {
    const { target } = evt;
    const checkbox = target.querySelector('input[type=checkbox]');
    if (checkbox) {
      checkbox.click();
      evt.stopPropagation();
    }
  }

  visibleProcess(wrapper: HTMLElement): void {
    wrapper.querySelectorAll('input[type=text]').forEach((item) => {
      const val = (<HTMLInputElement>item).value || '';
      const vkey = item.getAttribute('vkey') || '';
      if (val == '') {
        wrapper.querySelectorAll('input[type=checkbox][hkey][vkey="' + vkey + '"]').forEach((checkbox) => {
          (<HTMLInputElement>checkbox).style.visibility = 'hidden';
          checkbox.classList.add('invisible');
          checkbox.setAttribute('disabled', 'disabled');
          checkbox.removeAttribute('checked');
        });
      } else {
        wrapper.querySelectorAll('input[type=checkbox][hkey][vkey="' + vkey + '"]').forEach((checkbox) => {
          (<HTMLInputElement>checkbox).style.visibility = 'visible';
          checkbox.classList.remove('invisible');
          checkbox.removeAttribute('disabled');
          //checkbox.removeAttribute('checked');
        });
      }
    });
  }

  etcKeyup(): void {
    this.visibleProcess(this.$refs['s-answer']);
  }

  maxCheck(wrapper: HTMLElement): void {
    const { MAX_CHECK = '9999', NAME: qname } = this.data;
    const maxCheck = Number(MAX_CHECK);
    const trAll: NodeListOf<HTMLElement> = wrapper.querySelectorAll('tr[qname="' + qname + '"][vKey]');
    trAll.forEach((item) => {
      const vKey = item.getAttribute('vKey');
      let answerCount = 0;
      const trChildCheckbox = item.querySelectorAll(
        'input[qname="' + qname + '"][vKey="' + vKey + '"]'
      ) as NodeListOf<HTMLInputElement>;
      trChildCheckbox.forEach((item) => {
        if (item.checked) answerCount++;
      });

      if (answerCount >= maxCheck) {
        item.querySelectorAll('input[type=checkbox][data-column]:not(:checked)').forEach((item) => {
          item.setAttribute('disabled', 'disabled');
          const label = item.closest('label');
          if (label) label.style.opacity = '0.3';
        });
      } else {
        item.querySelectorAll('input[type=checkbox][data-column][disabled]').forEach((item) => {
          item.removeAttribute('disabled');
          const label = item.closest('label');
          if (label) label.style.opacity = '';
        });
      }
    });
  }
}
