
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { IHelp, BOARD } from '@/types/board';
import NoticeTable from "@/components/board/admin/NoticeTable.vue";

@Component({
  components: {
    NoticeTable,
  }
})
export default class AdminHelpList extends Vue {
  BOARD: Object = BOARD;
  loading: boolean = true;
  helpList: IHelp[] = [];
  page: number = 1;
  totalRow: number = 0;
  limitPage: number = 10;
  search: string = '';
  searchButtonHover: boolean = true;

  tabIndex = Number(this.$route.query.tabIndex) || 0;

  async created() {
    await this.load();
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  async load() {
    try {
      const sendData = {
        limit: this.limitPage,
        page: this.page,
        search: this.search,
      };
      const { data } = await this.axios.get(`/board/select-help`, { params: sendData });
      const { result, count, list } = data;
      if (result == true) {
        this.helpList = list;
        this.totalRow = count;
      }
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  get helpListLength(): number {
    return this.helpList.length;
  }

  categoryVariant(category: BOARD.HELP_CATEGORY): string {
    if (category === BOARD.HELP_CATEGORY.NOTICE) return 'warning';
    else if (category === BOARD.HELP_CATEGORY.USE) return 'success';
    else return 'primary';
  }

  helpView(index: number) {
    const list: IHelp = this.helpList[index];
    const { _id } = list;
    this.$router.push(`/board/admin-help-read/${_id}`);
  }
}
