



















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import InfiniteLoading from 'vue-infinite-loading';
import { ISurveyValue } from '@/interface/survey/question';
import { ITemplate } from '@/interface/template/template';
import type { IQuotaValue } from '@/interface/survey/quota';
import TemplateCard from '@/components/project/template/TemplateCard.vue';
import TemplatePreview from '@/components/main/preview/TemplatePreview.vue';
import { QUESTION } from '@/types/question';
import defaultSurveyInfo = QUESTION.defaultSurveyInfo;
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

interface IKeywordTemplate extends ITemplate {
  searchText: string;
}

@Component({
  components: {
    TemplatePreview,
    TemplateCard,
    InfiniteLoading,
  },
})
export default class CreateNewTemplate extends Vue {
  loading: boolean = true;
  showing: boolean = false;
  templateId: number = +this.$route.query.templateId;
  templateDisable: boolean = true;
  templateTitleArray: Array<{ _id: string; count: number }> = [];
  templateArray: Array<ITemplate> = [];
  previewMode: boolean = false;
  templateInfo: ITemplate = {
    ANSCOUNT: '',
    CATEGORY: '',
    CATEGORY_NAME: '',
    CHECKED: false,
    CONFIG: defaultSurveyInfo['CONFIG'],
    DATA: [],
    HASHTAG: [],
    ROLE: [],
    TIME: '',
    TITLE: '',
    PLACEHOLDER: '',
    description: '',
    isUse: false,
    surveyFix: false,
    _id: 0,
  };
  existTemplateId: boolean = true;
  templateSideToggle: boolean = true;
  search: string = '';
  page: number = 1;
  limit: number = 9;
  categoryId: string = '';
  keyWordTemplate: IKeywordTemplate[] = [];
  choiceTemplateId: number = 0;
  infiniteId = +new Date();
  modalFlag: string = 'side';
  isMakingQuestion: boolean = false;
  completeLoad: boolean = false;
  searchButtonHover: boolean = true;
  @Prop() private items: Array<ISurveyValue>;
  @Prop() private quotaData: IQuotaValue;

  async created() {
    if (this.templateId !== 0) await this.setSelectedTemplateInfo();
    await this.getCategoryList();
  }

  async mounted(): Promise<void> {
    if (this.existTemplateId) await this.selectedTemplate();
  }

  activeSearchButton() {
    this.searchButtonHover = !this.searchButtonHover;
  }

  prev() {
    this.templateArray = [];
    this.previewMode = !this.previewMode;
  }

  async getTemplateInfo() {
    this.page = 1;
    return this.axios.get('/template/selectedTemplate', { params: { templateId: this.templateId } });
  }

  async setSelectedTemplateInfo() {
    try {
      const { data } = await this.getTemplateInfo();
      const { result, template } = data;
      if (result && template[0].length) {
        const { CATEGORY } = template[0];
        this.categoryId = CATEGORY;
        this.templateIdComputed = false;
        this.templateDisable = false; // 리스트 선택되도록
      }
      setTimeout(() => {
        this.loading = false;
        this.templateDisable = true;
      }, 2000);
      this.existTemplateId = false;
    } catch (e) {
      console.log(e);
    }
  }

  async selectedTemplate() {
    try {
      const { data } = await this.getTemplateInfo();
      const { result, template } = data;
      if (result && template.length) {
        const { CATEGORY } = template[0];
        setTimeout(async () => {
          await this.categorySearch(0, CATEGORY);
        }, 1500);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async searchTemplate($state) {
    try {
      this.templateDisable = true;
      if (!this.categoryId) {
        setTimeout(() => {
          $state.loaded();
        }, 1200);
        $state.complete();
      }

      const sendData = {
        search: this.search,
        category: this.categoryId,
        page: this.page,
        limit: this.limit,
      };

      const { data } = await this.axios.get(`/template/category/search`, { params: sendData });
      const { result, list } = data;

      if (list.length > 0 && result) {
        setTimeout(() => {
          this.templateArray.push(...list);
          this.page++;
          $state.loaded();
          this.templateDisable = false;
        }, 1000);
        this.loading = false;
      } else {
        $state.complete();
        this.templateDisable = false;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getCategoryList() {
    try {
      this.showing = true;
      const { data } = await this.axios.get(`/template/category`);

      const { categoryList, result } = data;
      if (result && categoryList.length > 0) {
        this.templateTitleArray = categoryList;
        this.categoryId = this.templateTitleArray.length > 0 ? this.templateTitleArray[0]._id : '';
      } else {
        this.$common.makeToast(ToastMessage.TEMPLATE_GALLERY_ERROR, ToastVariant.DANGER, this.$bvToast);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.showing = false;
    }
  }

  async createNewProject(flag: boolean): Promise<void> {
    try {
      const createType = Number(this.$route.params.id); // 1: pmi 패널 대상 2:리스트 대상(알아서)
      this.isMakingQuestion = true;
      const sendData = {
        userId: this.$store.getters.userId,
        TITLE: flag ? this.templateInfo.TITLE : '',
        PLACEHOLDER: '제목을 입력해주세요.',
        QUOTA: {
          RESPONSE_CNT: this.quotaData?.RESPONSE_CNT,
          GENDER: this.quotaData?.GENDER,
          AGE_UNIT: this.quotaData?.AGE_UNIT,
          AGE_10: this.quotaData?.AGE_10,
          AGE_5: this.quotaData?.AGE_5,
          QUOTA_SET: this.quotaData?.QUOTA_SET,
          QUOTA_CNT: this.quotaData?.QUOTA_CNT,
          AREA: this.quotaData?.AREA,
        },
        createType: createType,
      };
      const { data } = await this.axios({
        url: '/project/new',
        method: 'POST',
        data: sendData,
      });

      const { sNum, result } = data;
      if (result) {
        this.$common.makeToast(ToastMessage.CREATE_PROJECT, ToastVariant.SUCCESS, this.$bvToast);
        await this.$router.push(`/project/make/${sNum}`);
      }
    } catch (e) {
      console.log(e);
      this.isMakingQuestion = false;
    }
  }

  async categorySearch(index: number, templateId?: string) {
    let id = '';
    if (templateId?.length) {
      id = templateId;
    } else {
      const { _id } = this.templateTitleArray[index];
      id = _id;
    }
    this.templateIdComputed = true;
    this.loading = true;
    this.infiniteId += 1;
    this.search = '';
    this.keyWordTemplate = [];
    this.page = 1;
    this.choiceTemplateId = this.templateId;
    this.categoryId = id;
    this.templateArray = [];
    this.templateDisable = true;
  }

  async keyWordSearch() {
    try {
      if (this.search.length < 2) return;
      const keywordArray = this.keyWordTemplate.map((item) => item.searchText);
      const keywordIndex = keywordArray.indexOf(this.search);
      //검색창 data-list 에서 클릭했 을 경우
      if (keywordIndex > -1) {
        const { _id, CATEGORY } = this.keyWordTemplate[keywordIndex];
        this.choiceTemplateId = _id;
        this.categoryId = CATEGORY;
        this.search = '';
        this.page = 1;
        this.infiniteId += 1;
        this.templateArray = [];
        const card = document.getElementById(`template-card-${_id}`) || document.createElement('div');
        card.scrollIntoView();
      } else {
        // 검색어 없을 경우
        const sendData = {
          search: this.search,
          category: '',
          page: 1,
          limit: 9999,
        };
        const { data } = await this.axios.get(`/template/category/search`, { params: sendData });
        const { result, list } = data;
        if (result === true && list.length) {
          list.forEach((item, itemIdx) => {
            const { CATEGORY, TITLE } = item;
            const isIt = this.keyWordTemplate.find((item) => item.searchText === `[${CATEGORY}] - ${TITLE}`);

            if (!isIt)
              this.keyWordTemplate.push({
                ...item,
                searchText: `[${CATEGORY}] - ${TITLE}`,
              });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  templatePreview(value: ITemplate) {
    this.page = 1;
    this.previewMode = true;
    this.templateInfo = value;
  }

  private get templateIdComputed() {
    return this.existTemplateId;
  }
  private set templateIdComputed(id: boolean) {
    this.existTemplateId = id;
  }
}
