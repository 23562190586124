import { render, staticRenderFns } from "./UnisurveyFooter.vue?vue&type=template&id=bac9607e&scoped=true&"
import script from "./UnisurveyFooter.vue?vue&type=script&lang=ts&"
export * from "./UnisurveyFooter.vue?vue&type=script&lang=ts&"
import style0 from "./UnisurveyFooter.vue?vue&type=style&index=0&id=bac9607e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac9607e",
  null
  
)

export default component.exports