

























import { Component, Prop, Vue } from 'vue-property-decorator';
import { IQuestionValue } from '@/interface/survey/question';

@Component
export default class QuestionModalDelete extends Vue {
  @Prop({ default: '' }) modalId: string;
  @Prop({ default: '' }) question: IQuestionValue;
  isLoading = true;

  init() {
    this.isLoading = false;
  }

  close() {
    // this.$emit('update', true);
    this.$bvModal.hide(this.modalId);
  }

  async remove() {
    // this.$emit('update', false);
    this.isLoading = true;
    const { QNUM, NAME } = this.question;
    const result = await this.$question.remove(QNUM, NAME);
    if (result) await this.close();
  }
}
