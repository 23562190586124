

import {Component, Vue} from "vue-property-decorator";

@Component
export default class Logout extends Vue {

  async created() {
    await Promise.all([
      await this.$store.commit('socketStore/setUserId', ''),
      await this.$store.dispatch('logOut', {historyId: this.getHistoryId}),
    ])

    await this.$router.replace('/');
  }

  get getHistoryId() {
    return this.$store.getters["historyId"];
  }
}
