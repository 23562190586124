









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionAnswerCheck extends Vue {
  @Prop({ required: true }) leftCategory: boolean;
  radioValue: boolean = false;
  label: string = '분류 위치';

  options: IArrayOption[] = [
    { text: '상단', value: false },
    { text: '좌측', value: true },
  ];

  created() {
    this.radioValue = this.leftCategory;
  }

  answerCheckChange() {
    this.radioValue = !this.radioValue;
    this.$emit('set-data', this.radioValue);
  }

  private showManual(radioValue) {
    const top = require('@/assets/images/options/items-top.png');
    const left = require('@/assets/images/options/items-left.png');

    let content = '';
    // top
    if (!radioValue) {
      content = `<div>
                    <h1 class="option-description--text">보기의 분류 위치를 상단 또는 좌측으로 설정할 수 있습니다.</h1>
                    <hr />
                    <b class="option-description--text-blue mt-2">선택 옵션 : [ 상단 ]</b>
                    <img alt="보기고정1" style='width: 100%;' src="${top}" />
                </div>`;
    } else {
      content = `<div>
                    <h1 class="option-description--text">보기 내용을 작성한 순서와 상관 없이 무작위로 제시합니다.</h1>
                    <hr />
                    <b class="option-description--text-blue mt-2">선택 옵션 : [ 좌측 ]</b>
                    <img alt="보기고정1" style='width: 100%;' src="${left}" />
                </div>`;
    }
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: content,
    };
  }
}
