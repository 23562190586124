import { render, staticRenderFns } from "./OptionDescription.vue?vue&type=template&id=b3e42870&scoped=true&"
import script from "./OptionDescription.vue?vue&type=script&lang=ts&"
export * from "./OptionDescription.vue?vue&type=script&lang=ts&"
import style1 from "./OptionDescription.vue?vue&type=style&index=1&id=b3e42870&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3e42870",
  null
  
)

export default component.exports