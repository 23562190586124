




















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class FixedIcon extends Vue {
  @Prop({default: false}) active?: boolean;
}
