



























































import { Component, Vue } from 'vue-property-decorator';
import { ToastVariant } from '@/utils/ToastEnum';

@Component
export default class JoinVerifyFail extends Vue {
  userId: string;
  userPwd: string;

  constructor() {
    super();
    this.userId = '';
    this.userPwd = '';
  }

  redirectLogin() {
    this.$router.replace({ path: '/login' });
  }

  async mailResend() {
    try {
      const url = `/user/auth-mail-resend`;
      const sendData = { userId: this.userId, userPwd: this.userPwd };
      const { data } = await this.axios.post(url, sendData);
      const { result, message } = data;
      if (result) {
        this.$common.makeToast(message, ToastVariant.DANGER, this.$bvToast);
      }
    } catch (e) {
      console.log(e);
    }
  }
  get token() {
    return this.$route.params.token;
  }
}
