









import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {ToastMessage, ToastVariant} from "@/utils/ToastEnum";

@Component
export default class QuestionImageUploader extends Vue {
  @Prop() forId: string;
  @Prop({ default: -1 }) index?: number;

  file: any = [];
  dataUrl = '';

  async fileUpload(e) {
    try {
      this.file = [];
      const files = e.target.files;
      if (!files.length) return;

      const typeCheck = ['jpg', 'jpeg', 'png'];
      const {type} = files[0];
      const [image, imageType] = type.split('/');

      if (image !== 'image' || !typeCheck.includes(imageType)) {
        this.file = [];
      } else {
        this.file = files
        this.dataUrl = await this.getDataUrl(files[0]);
      }
    } catch (e) {
      this.$common.makeToast('이미지 업로드에 실패하였습니다.', ToastVariant.DANGER, this.$bvToast);
    } finally {
      this.uploadResult(this.file[0]);
    }
  }
  //
  // async getDataUrl(files: Blob[]) {
  //   return window.URL.createObjectURL(files[0]);
  // }

  async getDataUrl(file: File): Promise<string> {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => resolve(reader.result + '');
    });
  }

  @Emit('uploadData')
  uploadResult(file) {
    this.file = [];
    let index = -1;
    if (this.index !== null && this.index !== undefined) {
      index = this.index;
    }
    return {
      file: file,
      dataUrl: this.dataUrl,
      index,
    };
  }
}
