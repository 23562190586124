




































import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { VueEditor } from 'vue2-editor';
import ImageUpload from "@/components/common/ImageUpload.vue";
import QuestionImageUploader from "@/components/common/QuestionImageUploader.vue";


@Component({
  components: {
    ImageUpload,
    VueEditor,
    QuestionImageUploader
  },
})
export default class OptionQuestion extends Vue {
  @Prop({ required: true }) readonly question: Required<string>;
  @Prop() create: boolean;
  @Ref() editor: VueEditor;

  uploadImages: File[] = [];
  dataUrlList: string[] = [];
  customToolbar = [];
  questionValue = '';
  count = 0;
  placeholder = '질문을 입력해주세요.';

  mounted() {
    this.questionValue = this.replaceQuestionTitle;
    const editor: NodeListOf<HTMLElement> = document.querySelectorAll('.question-editor');
    editor.forEach((item) => {
      const div: HTMLElement = item.querySelector('div.ql-editor') || document.createElement('div');
      div.style.minHeight = '0px';
    });
    const toolbar: NodeListOf<HTMLElement> = document.querySelectorAll('.ql-toolbar');
    toolbar.forEach((item) => {
      item.remove();
    });
  }

  get replaceQuestionTitle() {
    return this.question.replace(/<div/gi, '<p')
      .replace(/div>/gi, 'p>')
      .replace(/<b/gi, '<strong')
      .replace(/b>/gi, 'strong>')
  }

  private imageAppend(data: { file: File; dataUrl: string }) {
    this.dataUrlList = [];
    this.uploadImages = [];

    this.uploadImages.push(data.file);
    this.dataUrlList.push(data.dataUrl);

    const sendData = {
      file: this.uploadImages,
      dataUrl: this.dataUrlList
    }
    const imgTag = `<img src="${data.dataUrl}" style="object-fit: contain; max-width: 100%; max-height: 100%; width: fit-content; height: auto;" alt="question-title-image" />`;
    const findImage = this.questionValue.indexOf('img') > -1;
    if (findImage) {
      this.questionValue = this.questionValue.replace(/<IMG(.*?)>/gi, imgTag);
    } else {
      this.questionValue = imgTag + this.questionValue;
    }
    this.$store.dispatch('makeStore/addQuestionFile', [sendData]);
  }

  setData() {
    if (this.create && this.count === 0) {
      this.questionValue = '';
      this.count++;
    }
    if (this.questionValue.length > 0) {
      const quill = this.editor.quill;
      const size = quill.getLength();
      if (size >= 149) quill.deleteText(149, size);
    }

    const sendData = {
      question: this.questionValue,
      file: this.uploadImages,
      dataUrl: this.dataUrlList
    }
    this.$emit('set-data', sendData);
  }

  removeImage() {
    this.dataUrlList = [];
    this.uploadImages = [];
  }

  popoverConfigData() {
    const url = require('@/assets/images/question-tooltip.png');
    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      customClass: '',
      title: () => {
        return ``;
      },
      content: `<div><img alt="설명문" style='width: 280px;' src="${url}" /></div>`,
    };
  }
}
