



















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import HorizontalBarChart from '@/components/project/result/chart/HorizontalBarChart.vue';
import BarChart from '@/components/project/result/chart/BarChart.vue';
import PieChart from '@/components/project/result/chart/PieChart.vue';
import html2canvas from 'html2canvas';

import { IQuestionValue } from '@/interface/survey/question';
import ResultChartTopButton from '@/components/project/result/content/top/ResultChartTopButton.vue';
import { IChartData, IViewOption } from '@/interface/survey/chart';
import { RESULT } from '@/types/result';
import { QUESTION } from '@/types/question';
import ChartSideRowInformation from '@/components/project/result/content/side/ChartSideRowInformation.vue';
import QuotaParticipation from '@/components/project/quota/participation/QuotaParticipation.vue';
import TextTable from '@/components/project/result/textTable/TextTable.vue';
import EventBus from '@/utils/EventBus';
import QUESTION_TYPES = QUESTION.QUESTION_TYPES;
import CHART_TYPE = RESULT.CHART_TYPE;
import SORT_TYPE = RESULT.CHART_SORT_TYPE;
import jsPDF from "jspdf";
import {PDF} from "@/types/constant";

@Component({
  components: {
    ChartSideRowInformation,
    ResultChartTopButton,
    QuotaParticipation,
    TextTable,
    HorizontalBarChart,
    BarChart,
    PieChart,
  },
})
export default class ResultChartContent extends Vue {
  @Prop({ default: [] }) questionInfo: IQuestionValue[];
  @Prop({ default: '' }) private currentDataName: string;
  private svyNumber: number = +this.$route.params.id;

  loading: boolean = true;
  svyTitle: string = '';
  reportFirstPage = require('@/assets/REPORT.png');
  font = require('@/assets/fonts/malgun.ttf');

  customQuestionInfo: IQuestionValue[] = [];
  rankColor: string = '#FC4F4F';
  chartType: {
    [QNUM: string]: CHART_TYPE;
  } = { Q004: CHART_TYPE.BAR };
  progressValue: number = 10;
  sortType: number = 0;
  chartButton: boolean = false;
  buttonHide: boolean = false;

  async created() {
    await this.init();
    EventBus.$on('down-pdf', this.chartAllDown);
  }

  async init() {
    try {
      this.loading = true;
      const { data } = await this.axios.get(`/project/info/${this.svyNumber}`);
      this.svyTitle = data.info.TITLE || 'TITLE';
      this.loading = false;
    } catch (e) {
      console.log(e);
    }
  }

  beforeDestroy() {
    EventBus.$off('down-pdf');
  }

  mounted() {
    this.setCustomQuestionList();
  }

  setDataName(name: string) {
    return this.$emit('setDataName', name);
  }

  questionSwitch(name: string) {
    if (this.currentDataName === name) return;
    this.setDataName(name);
    this.navScrollEvent(name);
    setTimeout(() => {
      const ele = document.getElementById(`view-${name}`);
      if (ele) {
        ele.scrollIntoView({
          behavior: `smooth`,
        });
      }
    }, 100);
  }

  navScrollEvent(name: string) {
    const navi = document.getElementById(`make-${name}`);
    if (navi) {
      navi.scrollIntoView({
        behavior: `auto`,
        block: 'center',
      });
    }
  }

  async getChartType() {
    const { data } = await this.axios.get(`/result/type/${this.svyNumber}`);
    this.chartType = data;
  }

  cardClass(name: string) {
    const isEqualName = this.currentDataName === name;
    const defaultClass = '';
    const addPrimaryClass = defaultClass + ' selectedSurvey';
    return isEqualName ? addPrimaryClass : defaultClass;
  }

  @Watch('questionInfo')
  async setQuestion() {
    this.setCustomQuestionList();
    await this.getChartType();
  }

  @Watch('currentDataName')
  async questionScrollTo() {
    const question = this.questionInfo.find((item) => item.NAME === this.currentDataName);
    if (question) {
      const { TYPE = '' } = question;
      const ele = document.getElementById(`view-${this.currentDataName}`);
      if (ele) ele.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
    }
  }

  setCustomQuestionList() {
    this.customQuestionInfo = this.questionInfo.map((questionInfo) => {
      const { CHART_DATA } = questionInfo;
      const isSessionType = !!this.chartType[questionInfo.QNUM];
      const viewType = isSessionType ? this.chartType[questionInfo.QNUM] : CHART_DATA?.CHART_VIEW;
      const defaultViewObj = [
        { label: '%', state: true },
        { label: 'N', state: false },
      ];
      return {
        ...questionInfo,
        CHART_VIEW: viewType,
        CHART_SORT: SORT_TYPE.DEFAULT,
        CHART_N_PERCENT: defaultViewObj,
        CHART_VIEW_SELECT: 'percent',
      };
    });
  }

  setViewType_N_Percent(questionIdx: number) {
    if (this.customQuestionInfo[questionIdx] && this.customQuestionInfo[questionIdx].CHART_N_PERCENT) {
      const selectList = this.customQuestionInfo[questionIdx].CHART_N_PERCENT as {
        label: string;
        state: boolean;
      }[];
      const selectIdx = selectList.filter((value) => value.state).length;
      // 전체 클릭
      if (selectIdx === 2) {
        this.setChartViewSelect(questionIdx, 'all');
        return;
      }
      // 1개 클릭시 확인
      if (selectIdx) {
        const [percentInfo, NInfo] = selectList;
        if (percentInfo.state) {
          this.setChartViewSelect(questionIdx, 'percent');
          return;
        }
        if (NInfo.state) {
          this.setChartViewSelect(questionIdx, 'n');
          return;
        }
      } else {
        this.setPercentDefault(questionIdx);
        this.setChartViewSelect(questionIdx, 'percent');
        return;
      }
    }
  }

  setPercentDefault(questionIdx) {
    (
      this.customQuestionInfo[questionIdx].CHART_N_PERCENT as {
        label: string;
        state: boolean;
      }[]
    )[0].state = true;
  }

  setChartViewSelect(questionIdx, type: IViewOption) {
    this.customQuestionInfo[questionIdx].CHART_VIEW_SELECT = type;
  }

  getChartData(
    question: IQuestionValue,
    rowIdx: number,
    chartViewOption: { label: string; state: boolean }[]
  ): IChartData[] {
    if (question.CHART_DATA) {
      const chartData = question.CHART_DATA.COL[rowIdx];
      const resultObj: IChartData[] = [...chartData];
      const isDefault = question.CHART_SORT === SORT_TYPE.DEFAULT;
      const returns = this.dataSort(resultObj, isDefault);
      return this.colored(returns);
    } else {
      return [];
    }
  }

  colorArray: string[] = [
    'rgba(54,181,232,1)',
    'rgba(83,178,172,1)',
    'rgba(77,108,239,1)',
    'rgba(253,241,0,1)',
    'rgba(253,200,119,1)',
    'rgba(17,121,150,1)',
    'rgba(98,135,44,1)',
  ];

  colored(returnObj: IChartData[]): IChartData[] {
    return returnObj.map((obj, idx) => {
      for (let i = 0; i < 7; i++) {
        if (obj.key === '' + (i + 1)) {
          obj.color = this.colorArray[i];
        }
      }
      return obj;
    });
  }

  getBarHeight(question: IQuestionValue, rowIdx: number) {
    if (question.CHART_DATA) {
      const chartData = question.CHART_DATA.COL[rowIdx];
      const resultObj: IChartData[] = [...chartData];
      if (resultObj.length > 10) {
        return 164 + (resultObj.length - 3) * 40;
      } else {
        return 440;
      }
    } else {
      return [];
    }
  }

  dataSort(returnObj: IChartData[], isDefault: boolean): IChartData[] {
    returnObj.sort((a, b) => (a.percent > b.percent ? -1 : 1));
    returnObj[0].color = this.rankColor;
    if (isDefault) {
      return returnObj.sort((a, b) => (a.key > b.key ? 1 : -1));
    } else {
      return returnObj;
    }
  }

  isPie(type: CHART_TYPE): boolean {
    return type === CHART_TYPE.PIE;
  }

  getQuestionTitle(question: IQuestionValue) {
    return `[${question.QNUM}]${question.QUESTION}`;
  }

  async changeView(type: CHART_TYPE, idx: number) {
    this.customQuestionInfo[idx].CHART_VIEW = type;

    const sendData = {
      surveyId: '' + this.svyNumber,
      QNUM: this.customQuestionInfo[idx].QNUM,
      TYPE: this.customQuestionInfo[idx].CHART_VIEW,
    };
    try {
      await this.axios.post('/result/type', sendData);
    } catch (e) {
      console.log('보기 타입 저장 중 오류');
    }
  }

  changeSort(type: SORT_TYPE, idx: number) {
    this.customQuestionInfo[idx].CHART_SORT = type;
  }

  isOnlyBar(questionType: IQuestionValue): boolean {
    const { TYPE } = questionType as { TYPE: QUESTION_TYPES };
    const onlyBarTypeList = [QUESTION_TYPES.RADIOSET];
    return onlyBarTypeList.includes(TYPE);
  }

  isRows(question: IQuestionValue): boolean {
    return question.CHART_DATA?.ROW.length !== 1;
  }

  async getDataInfo(chartElement: HTMLElement): Promise<{ height: number; dataUrl: string }> {
    return await new Promise((resolve) => {
      html2canvas(chartElement).then((canvas) => {
        const height = canvas.height;
        const dataUrl = canvas.toDataURL('image/jpeg');

        return resolve({
          height: height,
          dataUrl: dataUrl,
        });
      });
    });
  }

  private regReplacer(word: string): string {
    const reg = /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/gi
    const title = word.replace(reg, "");
    const textSize = title.length;
    let convertTitle = '';
    //title  1줄에 최대 70 글자
    for (let i = 0; i <= textSize / 70; i++) {
      convertTitle += title.substring(i * 70, (i + 1) * 70)+'\n';
    }
    return convertTitle.replace(/&nbsp;/g, '');
  }

  async chartAllDown() {
    this.buttonHide = true;

    this.progressValue = 0;
    this.$bvModal.show('progress-modal');
    const totalCnt = this.customQuestionInfo.length;

    const doc = new jsPDF('p', 'mm', 'a4');
    doc.addImage(this.reportFirstPage, 'PNG', 0, 0, 210, 297);
    doc.addPage();

    let height = 10;

    for (let question of this.customQuestionInfo) {
      const questionIdx = this.customQuestionInfo.indexOf(question);
      this.progressValue = +((questionIdx / totalCnt) * 100).toFixed(2);
      const {QUESTION, QNUM} = question;

      const titleId = `view-title-${QNUM}`;
      const titleImage = document.getElementById(titleId);

      const contentInfo = question.CHART_DATA?.ROW;

      const malgun = PDF.font;

      doc.addFileToVFS('malgun.ttf', malgun);
      doc.addFont('malgun.ttf', 'malgun', 'normal');
      doc.setFont('malgun');
      doc.setTextColor('#565656');
      doc.setFontSize(9);

      if (titleImage && contentInfo) {
        const isPdfView = !titleImage.getAttribute('data-html2canvas-ignore');
        if (isPdfView) {
          doc.text(`[${QNUM}]`+ ' - ' + this.regReplacer(QUESTION), 5, height);
          height += 10;

          for (let i = 0; i < contentInfo.length; i++) {
            const {idx} = contentInfo[i];

            if (contentInfo.length > 1) {
              const subTitle2 = `view-subTitle-${QNUM}-row${idx}`;
              const subTitleImage = document.getElementById(subTitle2);

              if (subTitleImage) {
                const { dataUrl } = await this.getDataInfo(subTitleImage);
                doc.addImage(dataUrl, 5, height, 180, 25)
              }

              height += 25;
            }

            const contentId = `view-content-${QNUM}-row${idx}`;
            const contentImage = document.getElementById(contentId);

            if (contentImage) {
              const { dataUrl } = await this.getDataInfo(contentImage);

              doc.addImage(dataUrl, 'JPEG', 5, height, 180, 95);
              height += 110;

              if (height > 210) {
                if (this.customQuestionInfo.length-1 === questionIdx && contentInfo.length - 1 === idx) break;
                doc.addPage();
                height = 10;
              }
            }
          }
        }
      }
    }

    this.progressValue = 100;
    this.buttonHide = false;
    doc.save(`${this.svyTitle}.pdf`);
    setTimeout(() => {
      this.$bvModal.hide('progress-modal');
    }, 2000);
  }

  chartDown(chartId: string, fileName: string) {
    const chartElement = document.getElementById(chartId);

    if (chartElement) {
      html2canvas(chartElement, {
        backgroundColor: '#FFF',
      }).then((canvas: HTMLCanvasElement) => {
        const imageUrl = canvas.toDataURL('image/png');
        const downLink = document.createElement('a');

        downLink.setAttribute('target', '_blank');
        downLink.download = fileName + '.png';
        downLink.href = imageUrl;
        downLink.click();
      });
    }
  }

  stringSlice(inputString: string, sliceNumber: number = 10) {
    const isStringOver = inputString.length > sliceNumber;
    if (isStringOver) {
      return inputString.substring(0, sliceNumber) + '...';
    } else {
      return inputString;
    }
  }
}
