











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionValue } from '@/interface/survey/question';
import type { IArrayOption } from '@/interface/common';
import EventBus from '@/utils/EventBus';
import { QUESTION } from '@/types/question';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component
export default class QuestionModalChild extends Vue {
  @Prop({ required: true, default: [] }) makeList: IQuestionValue[];
  modalId: string = 'question-modal-child';
  checkQuestionOptions: IArrayOption[] = [];
  target: string = '';
  questionType: QUESTION.QUESTION_TYPES = QUESTION.QUESTION_TYPES.RADIO;
  showHide: QUESTION.CHILD_TYPE = QUESTION.CHILD_TYPE.SHOW;
  questionTypeOptions: IArrayOption[] = [
    { text: '하나만(단수형)', value: QUESTION.QUESTION_TYPES.RADIO },
    { text: '여러개(복수형)', value: QUESTION.QUESTION_TYPES.CHECK },
  ];
  showHideOptions: IArrayOption[] = [
    { text: '선택한 보기', value: QUESTION.CHILD_TYPE.SHOW },
    { text: '선택하지 않은 보기', value: QUESTION.CHILD_TYPE.HIDE },
  ];
  loading: boolean = false;

  @Watch('makeList')
  filterCheckQuestions() {
    this.checkQuestionOptions = this.makeList
      .filter((item) => {
        const { PARENT_NAME = '', TYPE, NAME } = item;
        return (
          TYPE === QUESTION.QUESTION_TYPES.CHECK && QUESTION.FIXED_QUESTIONS.indexOf(NAME) === -1 && PARENT_NAME === ''
        );
      })
      .map((item) => {
        return {
          text: item.QNUM,
          value: item.NAME,
        };
      });
    this.checkQuestionOptions.unshift({ text: '문항 선택', value: '' });
  }

  created() {
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_START, () => (this.loading = true));
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_END, () => (this.loading = false));
  }

  mounted() {
    this.filterCheckQuestions();
  }

  open() {
    this.target = '';
    this.filterCheckQuestions();
    this.$bvModal.show(this.modalId);
  }

  close() {
    this.$bvModal.hide(this.modalId);
  }

  async create() {
    if (this.target === '') {
      this.$common.makeToast(ToastMessage.CHOICE_EXAMPLE_QUESTION, ToastVariant.DANGER, this.$bvToast);
      return;
    }
    const sendData = {
      target: this.target,
      showHide: this.showHide,
    };
    const result = await this.$question.add(this.questionType, sendData);
    if (result) this.close();
  }
}
