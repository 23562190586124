

























import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class EyeIcon extends Vue {
  @Prop({default: false}) show: boolean
}
