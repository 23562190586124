
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
@Component
export default class OptionTableShow extends Vue {
  @Prop({ required: false, default: null }) byLine: boolean | null;
  @Prop({ required: true }) byCard: boolean;
  radioValue: string = '';
  label: string = '보기 제시 방법';
  options: IArrayOption[] = [
    { text: '전체 제시', value: '' },
    { text: '1줄씩 제시', value: 'BY_LINE' },
    { text: '카드형', value: 'BY_CARD' },
  ];

  created() {
    if (this.byLine) this.radioValue = 'BY_LINE';
    else if (this.byCard) this.radioValue = 'BY_CARD';
    else this.radioValue = '';

    if (this.byLine === null) {
      this.options = [
        { text: '전체 제시', value: '' },
        { text: '카드형', value: 'BY_CARD' },
      ];
    }
  }

  buttonActive(value: string, type: string) {
    if (type === 'noLine') {
      switch (value) {
        case '':
          return 0;
        case 'BY_CARD':
          return 1;
      }
    } else {
      switch (value) {
        case '':
          return 0;
        case 'BY_LINE':
          return 1;
        case 'BY_CARD':
          return 2;
      }
    }
  }

  changeButton(value: string | '') {
    this.radioValue = value;
    this.setData();
  }

  setData() {
    let byLine = false;
    let byCard = false;
    if (this.radioValue === '') {
      byLine = false;
      byCard = false;
    } else if (this.radioValue === 'BY_LINE') {
      byLine = true;
      byCard = false;
    } else if (this.radioValue === 'BY_CARD') {
      byLine = false;
      byCard = true;
    }
    this.$emit('set-data', { byLine, byCard });
  }

  private showManual(radioValue) {
    const all = require('@/assets/images/options/example-type-all.png');
    const oneLine = require('@/assets/images/options/example-type-one-line.png');
    const card = require('@/assets/images/options/example-type-card.png');

    let content = '';
    switch (radioValue) {
      case '':
        content = `<div>
                    <h1 class="option-description--text">보기를 제시하는 방법을 변경합니다</h1>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 전체 제시 ]</b>
                    <img alt="미제시" style='width: 100%;' src="${all}" />
                </div>`;
        break;
      case 'BY_LINE':
        content = `<div>
                    <h1 class="option-description--text">보기를 제시하는 방법을 변경합니다</h1>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 1줄씩 제시 ]</b>
                    <img alt="제시" style='width: 100%;' src="${oneLine}" />
                </div>`;
        break;
      case 'BY_CARD':
        content = `<div>
                    <h1 class="option-description--text">보기를 제시하는 방법을 변경합니다</h1>
                    <hr />
                    <b class="mt-2 option-description--text-blue">선택 옵션 : [ 카드형 ]</b>
                    <img alt="제시" style='width: 100%;' src="${card}" />
                </div>`;
        break;
    }

    return {
      html: true,
      delay: {
        show: 100,
      },
      placement: 'right',
      content: content,
    };
  }
}
