









import {Component, Vue} from "vue-property-decorator";
import {SOCIAL} from "@/constants/config";


interface KakaoSuccess {
  email: string;
  email_needs_agreement: boolean;
  has_email: boolean;
  is_email_valid: boolean;
  is_email_verified: boolean;
  profile_nickname_needs_agreement: boolean;
}

@Component
export default class KakaoLogin extends Vue {
  state: KakaoSuccess[] = [];

  async kakaoLogin () {
    try {
      const redirect = SOCIAL.KAKAO.CALLBACK;
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.VUE_APP_KAKAO_APP_KEY}&redirect_uri=${redirect}&response_type=code`;
      window.location.href = url;
    } catch (e) {
      console.log(e)
    }
  }
}
