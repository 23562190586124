






























































































import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { QUESTION } from '@/types/question';
import type { IQuestionValue } from '@/interface/survey/question';
import Radio from '@/components/project/make/join/Radio.vue';
import RadioSet from '@/components/project/make/join/RadioSet.vue';
import RadioSets from '@/components/project/make/join/RadioSets.vue';
import Check from '@/components/project/make/join/Check.vue';
import CheckSets from '@/components/project/make/join/CheckSets.vue';
import MultiText from '@/components/project/make/join/MultiText.vue';
import TextArea from '@/components/project/make/join/TextArea.vue';
import InfoDesc from '@/components/project/make/join/InfoDesc.vue';
import GradeClick from '@/components/project/make/join/GradeClick.vue';
import InfoTitle from '@/components/project/make/join/InfoTitle.vue';
import QuestionEdit from '@/components/project/make/question/QuestionEdit.vue';
import '@/assets/css/getdata/common.css';
import '@/assets/css/getdata/getdata.css';
import '@/assets/css/getdata/join.css';
import '@/assets/css/getdata/pure-min.css';
import '@/assets/css/skin/PMI2/index.css';
import TextBoxModal from '@/components/modal/make/option/TextBoxModal.vue';
import EventBus from '@/utils/EventBus';

const { QUESTION_TYPES } = QUESTION;

@Component({
  components: {
    Radio,
    RadioSet,
    MultiText,
    InfoDesc,
    GradeClick,
    InfoTitle,
    TextArea,
    RadioSets,
    Check,
    CheckSets,
    QuestionEdit,
    TextBoxModal,
  },
})
export default class QuestionLists extends Vue {
  @Prop({ required: true }) readonly item: IQuestionValue;
  @Prop({ default: [] }) readonly list: IQuestionValue[];
  @Prop() readonly dataIndex: number;
  @Prop({ default: '' }) readonly currentDataName: string;
  @Prop() readonly questionName: string[];

  editQuestion: IQuestionValue = QUESTION.tempQuestionData();
  QUESTION: Object = QUESTION;
  modalTitle: string = '';
  btnHover = false;

  @Emit('modalController')
  editOptions(type: string, qnum: string) {
    return { type, qnum };
  }

  @Watch('item')
  watchItem() {
    this.editQuestion = this.item;
    this.item.EDIT = false;
  }

  questionEdit(type: boolean) {
    this.item.EDIT = type;
  }

  closeEditorMode() {
    this.item.EDIT = !this.item.EDIT;
    EventBus.$emit(QUESTION.EVENT_FUNCTION.MAKE_QUESTION_RELOAD);
  }

  get questionTypeName(): string {
    const type = QUESTION.QUESTION_TYPES_LIST.find((question) => question.value === this.item.TYPE);
    const { text } = type || { text: '' };
    return text;
  }

  get isCurrent(): boolean {
    return this.currentDataName === this.item.NAME;
  }

  get isFixed(): boolean {
    return QUESTION.FIXED_QUESTIONS.indexOf(this.item.NAME) > -1;
  }

  btnHoverCheck(value) {
    this.btnHover = value;
  }

  questionOpen(QNAME) {
    if (this.item.EDIT || this.btnHover) return;
    this.questionSwitch(QNAME);
  }

  questionSwitch(QNAME) {
    this.$emit('questionSwitch', QNAME);
  }
}
