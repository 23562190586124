







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionDesc } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';

@Component({
  components: { QuestionHtml },
})
export default class InfoDesc extends Vue {
  @Prop() private data: IQuestionDesc;
  @Prop() private questionTypeName?: string;
}
