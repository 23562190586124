var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"question-list",style:(_vm.componentType === 'result' ? 'top: 200px;' : '')},[_vm._m(0),_c('b-overlay',{staticClass:"scroll scroll-type scroll-container",attrs:{"spinner-variant":"primary","opacity":"0.5","wrap-tag":"section","rounded":"","show":_vm.loading,"id":"listNavi"}},[(_vm.$route.path.includes('/project/make'))?_c('draggable',_vm._b({on:{"change":_vm.navigationSort},model:{value:(_vm.tempList),callback:function ($$v) {_vm.tempList=$$v},expression:"tempList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{class:_vm.dragClass,attrs:{"name":"navigation"}},_vm._l((_vm.tempList),function(qItem,qIndex){return _c('div',{key:("question-" + qIndex),staticClass:"question-list-group pointer",class:[
            ("field-item pr-2 pl-2 " + (qItem.NAME === _vm.currentDataName && 'active')),
            qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'navigation-active' : '',
            _vm.fixedCheck(qItem.QNUM) ? 'fixed-active' : 'list-items' ],attrs:{"data-name":qItem.NAME,"id":("make-" + (qItem.NAME))},on:{"click":function($event){return _vm.redirectQuestion(qItem.NAME)},"mouseenter":function($event){return _vm.questionHover(qItem.QNUM)},"mouseleave":function($event){_vm.questionHoverComputed = ''}}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center question-list-title"},[_c('img',{staticClass:"custom-pr-10",attrs:{"loading":"lazy","src":_vm.$question.questionTypeIcon(qItem),"alt":"아이콘"}}),_c('span',{staticClass:"d-flex align-items-center"},[_c('font',{staticClass:"custom-mr-6 make-qnum",class:_vm.fixedCheck(qItem.QNUM) && 'fixed-qnum',attrs:{"size":"10","color":"pointColor","weight":"semiBold"},domProps:{"innerHTML":_vm._s(_vm.convertFixedQuestion(qItem))}}),_c('font',{staticClass:"make-question-navigation-title",class:qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'title-active' : '',attrs:{"size":"13","weight":"medium","color":"textBody"},domProps:{"innerHTML":_vm._s(_vm.removeImageAndSpaceTag(qItem.QUESTION).length > 0 ? _vm.removeImageAndSpaceTag(qItem.QUESTION) : _vm.placeholder)}})],1)]),_c('div',{style:((qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM) &&
              _vm.$question.makeUpdateStatus &&
              _vm.$route.path.includes('/project/make') &&
              !_vm.fixedCheck(qItem.QNUM)
                ? 'display: block'
                : 'display: none')},[_c('b-dropdown',{staticClass:"drop-down-menu pt-2",attrs:{"variant":"none","no-caret":"","size":"sm","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('menu-icon')]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionMove(qItem.NAME)}}},[_vm._v("이동")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionCopy(qItem.NAME)}}},[_vm._v("복사")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.questionDelete(qItem.NAME)}}},[_vm._v("삭제")])],1)],1)])}),0)],1):_c('section',_vm._l((_vm.tempList),function(qItem,qIndex){return _c('a',{key:("question-" + qIndex),staticClass:"list-items question-list-group-result",class:[
          ("field-item p-2 " + (qItem.QNUM === _vm.currentDataName ? 'active' : '')),
         _vm.currentDataName === qItem.QNUM ? 'navigation-active' : '',
          _vm.fixedCheck(qItem.QNUM) ? 'fixed-active' : 'list-items' ],attrs:{"data-name":qItem.QNUM,"id":("make-" + (qItem.QNUM))},on:{"click":function($event){return _vm.redirectQuestion(qItem.QNUM)},"mouseenter":function($event){return _vm.questionHover(qItem.QNUM)},"mouseleave":function($event){_vm.questionHoverComputed = ''}}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center question-list-title"},[_c('img',{staticClass:"custom-pr-10",attrs:{"src":_vm.$question.questionTypeIcon(qItem),"alt":"아이콘","loading":"eager"}}),_c('span',{staticClass:"d-flex align-items-center pointer"},[_c('font',{staticClass:"custom-mr-6 make-qnum",class:_vm.fixedCheck(qItem.QNUM) ? 'fixed-qnum' : '',attrs:{"size":"10","color":"pointColor","weight":"semiBold"},domProps:{"innerHTML":_vm._s(qItem.QNUM || qItem.NAME)}}),_c('font',{staticClass:"make-question-navigation-title",class:qItem.NAME === _vm.currentDataName || _vm.questionHoverComputed === qItem.QNUM ? 'title-active' : '',attrs:{"size":"13","weight":"medium","color":"textBody"},domProps:{"innerHTML":_vm._s(_vm.removeImageAndSpaceTag(qItem.QUESTION).length > 0 ? _vm.removeImageAndSpaceTag(qItem.QUESTION) : _vm.placeholder)}})],1)])])}),0)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"question-group-title"},[_c('span',[_vm._v("질문 리스트")])])}]

export { render, staticRenderFns }