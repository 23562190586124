import { render, staticRenderFns } from "./QuestionWriteContainer.vue?vue&type=template&id=f6baba36&scoped=true&"
import script from "./QuestionWriteContainer.vue?vue&type=script&lang=ts&"
export * from "./QuestionWriteContainer.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionWriteContainer.vue?vue&type=style&index=0&id=f6baba36&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6baba36",
  null
  
)

export default component.exports