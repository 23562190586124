



























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SignUpSuccessModal extends Vue {
  close() {
    this.$bvModal.hide('join-success');
  }
}
