




























































import { Component, Vue } from 'vue-property-decorator';
import type { IArrayOption } from '@/interface/common';
import RequestAuthNumber from './RequestAuthNumber.vue';
import SetNewPw from '@/components/users/SetNewPw.vue';
import QuestionWriteModal from '@/components/board/QuestionWriteModal.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';

@Component({
  components: {
    SetNewPw,
    RequestAuthNumber,
    QuestionWriteModal,
  },
})
export default class FindPw extends Vue {
  inpEmailId: string = '';
  inpEmailAddress: string = '';
  selectEmail: string = '';
  emailArray: Array<IArrayOption> = [
    { value: '', text: '직접 입력' },
    { value: 'naver.com', text: 'naver.com' },
    { value: 'daum.net', text: 'daum.net' },
    { value: 'hanmail.net', text: 'hanmail.net' },
    { value: 'gmail.com', text: 'gmail.com' },
    { value: 'nate.com', text: 'nate.com' },
  ];
  authFlag: boolean = false;

  $refs: Vue['$refs'] & {
    findPwAuthNumber: RequestAuthNumber;
  };

  changeEmail(): void {
    this.inpEmailAddress = this.selectEmail;
  }

  get userId(): string {
    return `${this.inpEmailId}@${this.inpEmailAddress}`;
  }

  async search() {
    const email = this.userId;
    const result = await this.emailCheck(email);
    if (!result) {
      this.$common.makeToast(ToastMessage.NOT_EXIST_EMAIL, ToastVariant.WARNING, this.$bvToast);
      return;
    }

    this.authFlag = this.$refs.findPwAuthNumber.authNumberCheck();
    if (!this.authFlag) {
      this.$common.makeToast(ToastMessage.FIND_PASSWORD, ToastVariant.WARNING, this.$bvToast);
      return;
    }
  }

  async emailCheck(userId: string): Promise<boolean> {
    const { data } = await this.axios.post(`/user/duplicate-id/${userId}`);
    const { exists } = data;
    return exists;
  }

  reset() {
    this.inpEmailId = '';
    this.inpEmailAddress = '';
    this.selectEmail = '';
    this.authFlag = false;
    this.$refs.findPwAuthNumber.reset();
  }

  questionModalOpen() {
    this.$bvModal.show('question-write-modal');
  }
}
