


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { IQuestionTextArea } from '@/interface/survey/question';
import QuestionHtml from '@/components/project/make/join/QuestionHtml.vue';

@Component({
  components: {
    QuestionHtml,
  },
})
export default class TextArea extends Vue {
  @Prop() private data: IQuestionTextArea;
  @Prop() private questionTypeName?: string;

  $refs: Vue['$refs'] & {
    's-answer': HTMLElement;
  };

  async mounted() {
    await this.load();
    await this.initEvent();
  }

  async load(): Promise<void> {
    const {
      MIN_LENGTH = '1',
      MAX_LENGTH = '9999',
      TEXT_ALLOW = '',
      TEXT_DENY = '',
      HEIGHT = '150',
      NAME,
      PASS = '',
    } = this.data;

    const answerBox = this.$refs['s-answer'];
    const textareaWrapper = document.createElement('div');
    textareaWrapper.classList.add('display-flex');
    answerBox.appendChild(textareaWrapper);
    const input = document.createElement('textarea');
    textareaWrapper.appendChild(input);
    input.style.minWidth = '100%';
    input.style.height = `${HEIGHT}px`;
    input.setAttribute('qname', NAME);
    input.setAttribute('data-column', '1');
    input.setAttribute('data-min-length', MIN_LENGTH);
    input.setAttribute('data-max-length', MAX_LENGTH);

    //무응답 통과문구가 있는 경우
    if (PASS !== '') {
      const div = document.createElement('dv');
      div.classList.add('s-pass');
      answerBox.appendChild(div);

      const label = document.createElement('label');
      label.classList.add('pure-checkbox');
      label.setAttribute('for', `${NAME}_PASS`);
      div.appendChild(label);
      const input = document.createElement('input');
      input.setAttribute('type', 'checkbox');
      label.appendChild(input);
      input.setAttribute('id', `${NAME}_PASS`);
      input.setAttribute('qname', NAME);
      input.setAttribute('name', `${NAME}_PASS`);
      input.setAttribute('data-column', PASS);
      input.setAttribute('data-pass', 'true');

      const span = document.createElement('span');
      label.style.marginTop = '7px';
      span.innerHTML = PASS;
      label.appendChild(span);
    }
  }

  async initEvent(): Promise<void> {
    const answerBox = this.$refs['s-answer'] || document.createElement('div');
    const pass = answerBox.querySelector('input[data-pass="true"]');
    if (pass) pass.addEventListener('click', this.passEvent);
  }

  passEvent(e) {
    const { target } = e;
    const { checked } = target;
    const answerBox = this.$refs['s-answer'];
    const { NAME } = this.data;
    const textArea: NodeListOf<HTMLInputElement> = answerBox.querySelectorAll(`textarea[qname="${NAME}"]`);
    if (checked) {
      textArea.forEach((item) => {
        item.setAttribute('disabled', 'disabled');
        item.value = '';
      });
    } else {
      textArea.forEach((item) => {
        item.removeAttribute('disabled');
      });
    }
  }

  beforeDestroy() {}
}
