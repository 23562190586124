













import { Component, Vue } from 'vue-property-decorator';
import QuestionWriteContainer from '@/components/board/QuestionWriteContainer.vue';

@Component({
  components: {
    QuestionWriteContainer,
  },
})
export default class QuestionWrite extends Vue {}
