





























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { RESULT } from '@/types/result';
import CHART_TYPE = RESULT.CHART_TYPE;
import SORT_TYPE = RESULT.CHART_SORT_TYPE;
import { IQuestionValue } from '@/interface/survey/question';
import { QUESTION } from '@/types/question';

@Component({
  components: {},
})
export default class ResultChartContent extends Vue {
  @Prop({ default: [] }) questionInfo: IQuestionValue[];
  @Prop({ default: CHART_TYPE.DEFAULT }) viewType: CHART_TYPE;
  @Prop({ default: SORT_TYPE.DEFAULT }) sortType: SORT_TYPE;
  @Prop({ default: false }) onlyBar: boolean;
  @Prop({ default: '' }) title: string;
  @Prop({ default: 0 }) idx: number;

  MULTI_TEXT: QUESTION.QUESTION_TYPES = QUESTION.QUESTION_TYPES.MULTI_TEXT;
  TEXTAREA: QUESTION.QUESTION_TYPES = QUESTION.QUESTION_TYPES.TEXTAREA;

  private barType: CHART_TYPE = CHART_TYPE.BAR;
  private pieType: CHART_TYPE = CHART_TYPE.PIE;
  private defaultSort: SORT_TYPE = SORT_TYPE.DEFAULT;
  private rankSort: SORT_TYPE = SORT_TYPE.VALUE_RANK;

  changeView() {
    this.$emit('nPercent', this.idx);
  }

  viewBtn(type: CHART_TYPE) {
    this.$emit('toggle', type, this.idx);
  }

  sort(type: SORT_TYPE) {
    this.$emit('sort', type, this.idx);
  }

  get isPie(): boolean {
    return this.viewType === CHART_TYPE.PIE;
  }

  get sortDefault(): boolean {
    return this.sortType === SORT_TYPE.DEFAULT;
  }

  changeSort(type: SORT_TYPE, idx: number) {
    this.questionInfo[idx].CHART_SORT = type;
  }
}
