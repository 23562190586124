
































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import type { IProjectValue } from '@/interface/survey/question';
import { IArrayOption } from '@/interface/common';
import EventBus from "@/utils/EventBus";
import {QUESTION} from "@/types/question";

@Component
export default class SurveyOption extends Vue {
  @Prop({ required: true }) private projectInfo: IProjectValue;
  isLoading = false;
  modalId: string = 'survey-option-modal';
  progressOption: IArrayOption[] = [
    { text: '보여줌(%)', value: true },
    { text: '보여줌(페이지 수)', value: 'C' },
    { text: '보여주지 않음', value: false },
  ];
  mobileOption: IArrayOption[] = [
    { text: '참여 불가', value: true },
    { text: '참여 가능', value: false },
  ];
  mobileFlag: boolean = true;

  created(): void {
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_START, () => (this.isLoading = true));
    EventBus.$on(QUESTION.EVENT_FUNCTION.LOADING_END, () => (this.isLoading = false));
  }

  @Watch('projectInfo')
  watchProjectInfo() {
    const { CONFIG } = this.projectInfo;
    const { JOIN } = CONFIG;
    const { MOBILE_RESTRICT } = JOIN;
    MOBILE_RESTRICT.length > 1 ? (this.mobileFlag = true) : (this.mobileFlag = false);
  }

  @Emit('save')
  questionConfigSave() {
    return this.projectInfo
  }

  mounted() {
    this.watchProjectInfo();
  }

  showProgressClass(type: string | boolean) {
    return this.projectInfo.CONFIG.PROGRESS === type;
  }

  mobileClass(type: boolean) {
    return this.mobileFlag === type;
  }

  mobileSetup(value: boolean) {
    this.mobileFlag = value;
    this.projectInfo.CONFIG.JOIN.MOBILE_RESTRICT = this.mobileFlag ? '모바일로 참여할 수 없습니다.' : '';
  }

  progressSetup(value: string | boolean) {
    switch (value) {
      case true:
        this.projectInfo.CONFIG.PROGRESS = true;
        break;
      case 'C':
        this.projectInfo.CONFIG.PROGRESS = 'C';
        break;
      case false:
        this.projectInfo.CONFIG.PROGRESS = false;
        break;
    }
  }

  close() {
    this.$bvModal.hide(this.modalId);
  }

  open() {
    this.$bvModal.show(this.modalId);
  }
}
