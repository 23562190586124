import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import store from '@/store';
import app from '@/main';

import QuestionList from '@/views/board/question/QuestionList.vue';
import QuestionWrite from '@/views/board/question/QuestionWrite.vue';
import QuestionRead from '@/views/board/question/QuestionRead.vue';
import QuestionReply from '@/views/board/question/QuestionReply.vue';
import AdminHelpList from '@/components/board/admin/AdminHelpList.vue';
import AdminHelpRead from '@/components/board/admin/AdminHelpRead.vue';
import AdminHelpModify from '@/components/board/admin/AdminHelpModify.vue';
import AdminHelpWrite from '@/components/board/admin/AdminHelpWrite.vue';
import NewProject from '@/views/project/sampling/SamplingView.vue';
import FindInfo from '@/views/users/find/index.vue';
import JoinVerifySuccess from '@/views/users/sign-up/VerifySuccess.vue';
import ProjectList from '@/components/project/ProjectList.vue';
import Sampling from '@/components/project/make/Sampling.vue';
import Make from '@/components/project/make/Make.vue';
import NewMake from '@/components/project/make/NewMake.vue';
import Payment from '@/components/project/payment/Payment.vue';
import Quota from '@/components/project/quota/Quota.vue';
import JoinVerifyAlready from '@/views/users/sign-up/VerifyAlready.vue';
import JoinVerifyFail from '@/views/users/sign-up/VerifyFail.vue';
import VerifyWait from '@/views/users/sign-up/VerifyWait.vue';
import NotFoundPage from '@/views/error/404.vue';
import Result from '@/components/project/result/Result.vue';
import Views from '@/views/index.vue';
import Profile from '@/views/users/profile.vue';
import { ToastMessage, ToastVariant } from '@/utils/ToastEnum';
import SignOut from "@/utils/components/sign-out/index.vue";
import OAuthCallback from "@/components/users/sign-in/oauth/OAuthCallback.vue";
import UnisurveyIndexView from "@/views/index/UnisurveyIndexView.vue";
import AdminCheck from "@/components/utils/AdminCheck.vue";
import SignUpPage from "@/views/users/sign-up/index.vue";
import SignUpForm from "@/components/users/sign-up/SignUpForm.vue";
import SignUpSuccess from "@/components/users/sign-up/SignUpSuccess.vue";
import SurveyPriceInfo from "@/components/project/payment/SurveyPriceInfo.vue";
import PaymentSuccess from "@/components/project/payment/PaymentSuccess.vue";
import SignInView from "@/views/users/sign-in/SignInView.vue";
import {resultRedirectGuard} from "@/router/guards/redirectGuard";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: Views,
    meta: { unauthorized: true },
  },
  {
    path: '/index',
    component: UnisurveyIndexView,
    meta: { unauthorized: true },
    children: [
      {
        path: '/login',
        name: 'login',
        component: SignInView,
        meta: { unauthorized: true },
      },
      {
        path: '/oauth/callback/:type?',
        component: OAuthCallback,
        meta: { unauthorized: true },
      },
      {
        path: '/users/join',
        component: SignUpPage,
        meta: { unauthorized: true },
        children: [
          {
            path: '/',
            name: 'signup',
            component: SignUpForm,
            meta: { unauthorized: true },
          },
          {
            path: 'success',
            name: 'signup-success',
            component: SignUpSuccess,
            meta: { unauthorized: true },
          },
        ]
      },
      {
        path: '/users/join/verify-fail',
        name: 'joinVerifyFail',
        component: JoinVerifyFail,
        meta: { unauthorized: true },
      },
      {
        path: '/users/join/verify-already',
        name: 'joinVerifyAlready',
        component: JoinVerifyAlready,
        meta: { unauthorized: true },
      },
      {
        path: '/users/join/verify-success/:token',
        name: 'joinVerifySuccess',
        component: JoinVerifySuccess,
        meta: { unauthorized: true },
      },
      {
        path: '/users/join/verify-wait/:userId',
        name: 'join-verify-wait',
        component: VerifyWait,
        meta: { unauthorized: true },
      },
      {
        path: '/users/findPwd',
        name: 'findPwd',
        component: FindInfo,
        meta: { unauthorized: true },
      },
    ]
  },
  {
    path: '/users/profile/:page?',
    name: 'profile',
    component: Profile,
    meta: { unauthorized: true },
  },
  {
    path: '/project',
    component: () => import('@/views/project/ProjectView.vue'),
    children: [
      {
        path: 'list',
        name: 'projectList',
        component: ProjectList,
        meta: {},
      },
      {
        path: 'sampling/:id',
        name: 'surveySampling',
        component: Sampling,
        meta: { projectAccess: true },
      },
      {
        path: 'make/:id',
        name: 'surveyMake',
        component: Make,
        meta: { projectAccess: true },
      },
      {
        path: 'make/:id/:token',
        name: 'surveyMakeAdmin',
        component: AdminCheck,
        meta: { unauthorized: true },
      },
      {
        path: 'new-make/:token',
        name: 'surveyNewMake',
        component: NewMake,
        meta: {},
      },
      {
        path: 'payment',
        component: Payment,
        meta: { projectAccess: true },
        children: [
          {
            path: 'success/:id',
            name: 'paymentSuccess',
            component: PaymentSuccess
          },
          {
            path: ':id',
            name: 'surveyPayment',
            component: SurveyPriceInfo,
          },
        ]
      },
      {
        path: 'quota/:id',
        name: 'surveyQuota',
        component: Quota,
        meta: { projectAccess: true },
      },
      {
        path: 'new/:id',
        name: 'project-sampling',
        component: NewProject,
        meta: {},
      },
      {
        path: 'result/:id',
        name: 'surveyResult',
        component: Result,
        beforeEnter: resultRedirectGuard,
        meta: { projectAccess: true },
      },
      {
        path: 'result/:id/:token',
        name: 'surveyResultAdmin',
        component: AdminCheck,
        meta: { unauthorized: true },
      },
    ],
  },
  {
    path: '/project/preview/:sNum/:token',
    name: 'admin-preview',
    component: () => import('@/views/project/preview/PreviewSurvey.vue'),
    meta: { unauthorized: true },
  },
  {
    path: '/logout',
    component: SignOut,
    meta: { unauthorized: true },
  },
  {
    path: '/board',
    name: 'board-question',
    component: () => import('@/views/board/BoardView.vue'),
    meta: {},
    children: [
      {
        path: 'question-list',
        name: 'board-question-list',
        component: QuestionList,
        meta: {},
      },
      {
        path: 'question-write',
        name: 'board-question-write',
        component: QuestionWrite,
        meta: {},
      },
      {
        path: 'question-read/:listId',
        name: 'board-question-read',
        component: QuestionRead,
        meta: {},
      },
      {
        path: 'question-reply/:listId',
        name: 'board-question-reply',
        component: QuestionReply,
        meta: { role: 'ADMIN' },
      },
      {
        path: 'help-view/:helpId',
        name: 'board-help-view',
        component: () => import('@/views/board/HelpView.vue'),
        meta: { unauthorized: true },
      },
      {
        path: 'question-read/:listId/:token',
        name: 'admin-board-question-read',
        component: AdminCheck,
        meta: { unauthorized: true },
      },
      {
        path: 'question-write/:listId',
        name: 'board-question-add',
        component: QuestionWrite,
        meta: {},
      },
      {
        path: 'question-edit/:listId/:level',
        name: 'board-question-edit',
        component: QuestionReply,
        meta: {},
      },
      {
        path: 'admin-help-list',
        name: 'board-admin-help-list',
        component: AdminHelpList,
        meta: { role: 'ADMIN' },
      },
      {
        path: 'admin-help-read/:helpId',
        name: 'board-admin-help-read',
        component: AdminHelpRead,
        meta: { role: 'ADMIN' },
      },
      {
        path: 'admin-help-modify/:helpId',
        name: 'board-admin-help-modify',
        component: AdminHelpModify,
        meta: { role: 'ADMIN' },
      },
      {
        path: 'admin-help-write',
        name: 'board-admin-help-write',
        component: AdminHelpWrite,
        meta: { role: 'ADMIN' },
      },
    ]
  },
  //TODO: 템플릿 갤러리 추가되면 주석 해제
  // {
  //   path: '/project/sampling/:id/:template?',
  //   name: 'project-sampling',
  //   component: NewProject,
  //   meta: {},
  // },

  {
    path: '*',
    component: NotFoundPage,
    meta: { unauthorized: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach(async (to: Route, from, next) => {
  try {
    const { matched, name } = to;
    let verifyFlag = true;
    const isUnauthorized = matched.some((record) => record.meta.unauthorized);
    const { meta } = matched.find((record) => record.meta) || {};
    const { role }: any = meta || { role: '' };

    if (!isUnauthorized) {
      const { result } = await store.dispatch('verify');
      if (!result) {
        return next('/login');
      }
    }
    if (verifyFlag && role === 'ADMIN') {
      if (!store.getters.isAdmin) {
        await app.$common?.makeToast(ToastMessage.NOT_ACCESS, ToastVariant.DANGER, app.$bvToast);
        return next('/project/list');
      }
    }
    // 프로젝트 권한 검사
    const projectAccess = matched.some((record) => record.meta.projectAccess);
    if (projectAccess) {
      const { params } = to;
      const { id } = params;
      const { data } = await store.dispatch('projectAccess', id);
      const { result } = data;
      if (result) return next();
      else {
        await app.$common?.makeToast(ToastMessage.INVALID, ToastVariant.DANGER, app.$bvToast);
        return next('/project/list');
      }
    }

    // 로그인 상태에서 로그인 페이지 접근 시 메인으로 이동
    if (name === 'login' && store.getters.token) {
      const { result } = await store.dispatch('verify');
      if (result) return next('/project/list');
      else return next();
    }

    return next();
  } catch (e) {
    console.error(e);
  }
});

export default router;
