















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class JoinVerifyAlready extends Vue {
  redirectLogin() {
    this.$router.replace({ path: '/login' });
  }
}
