






































import { Component, Prop, Vue } from 'vue-property-decorator';
import {QUESTION} from "@/types/question";

@Component
export default class QuestionHtml extends Vue {
  @Prop({ required: true, default: '' }) QNUM: string;
  @Prop({ required: true, default: '' }) QUESTION: string;
  @Prop({ required: false }) DESC: string;
  @Prop() private questionTypeName?: string;

  // TITLE, DESC 문항 질문 안보여주기
  titleHide: string[] = ['설문 제목', '안내문'];
  placeholder: string = '<div>문항 제목을 입력해주세요.</div>';

  convertFixedQuestion(QNUM: string) {
    switch (QNUM) {
      case QUESTION.FIXED_QUESTIONS[0]:
        return '성별';
      case QUESTION.FIXED_QUESTIONS[1]:
        return '나이';
      case QUESTION.FIXED_QUESTIONS[2]:
        return '지역';
      default:
        return QNUM;
    }
  }

  fixedCheck(QNUM: string) {
    const fixedIndex = QUESTION.FIXED_QUESTIONS.indexOf(QNUM);
    return fixedIndex > -1;
  }
}
